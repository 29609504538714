import { Enums } from '../config/enums';
import * as actionTypes from './actionTypes';

import {
	addPerformanceCycleAPI,
	cycleDateOverlapAPI,
	deletePerformanceCycleAPI,
	isNewPerformanceRoleAPI,
	performanceCycleActiveCycleAPI,
	performanceCycleCycleAPI,
	performanceCycleExcludeEmployeeListAPI,
	performanceCycleIncludeEmployeeAPI,
	performanceCycleLinkedFormAPI,
	performanceCycleListAPI,
	performanceRoleAssignedAPI,
	performanceRoleDetailAPI,
	userDepartmentListAPI,
	publishPerformanceCycleAPI,
	ratingFormCreateAPI,
	ratingFormDeleteAPI,
	ratingFormDetailsDeleteAPI,
	ratingFormListAPI,
	ratingFormUpdateAPI,
	updatePerformanceCycleAPI,
	getQuestionTypeMasterDataAPI,
	getManageScaleListAPI,
	addScaleDetailsAPI,
	updateScaleDetailsAPI,
	getPerformanceTabCountAPI,
	updateManageScaleStatusAPI,
	deleteManageScaleAPI,
	performPreviewForm,
	goalFormListAPI,
	goalCycleListAPI,
	workflowReversalListAPI,
	reviewWorkflowReversalListAPI,
	goalReviewListAPI,
	goalCycleRecordListAPI,
	getWorkflowReversalCycleListAPI,
	goalCycleDetailsAPI,
	addGoalCycleAPI,
	isTimeframeOverlapAPI,
	updateGoalCycleAPI,
	deleteGoalCycleAPI,
	assignUserSearchByNameAPI,
	validateManualAssignedUserAPI,
	validateBulkAssignedUserAPI,
	getPerformanceGoalTabCountAPI,
	savePerformanceGoalFormAPI,
	getPerformanceGoalResponseTypeAPI,
	exportGoalFormAPI,
	updatePerformanceGoalFormAPI,
	deleteGoalFormAPI,
	getPerformanceGoalListAPI,
	getGoalCycleSelector,
	savePerformanceGoalUserAPI,
	getPerformanceGoalAttachmentAPI,
	getPerformanceGoalTeamDetailsAPI,
	performanceGoalFormDeleteReportAPI,
	performanceGoalFormDownloadStatusReportAPI,
	performanceGoalSettingReversalDownloadReportAPI,
	getPerformanceGoalResponseListAPI,
	exportGoalCycleAPI,
	validateBulkUploadGoalFormApi,
	bulkUploadGoalFormApi,
	exportEachGoalFormAPI,
	savePerformanceWorkflowAPI,
	searchOngoingApi,
	savePerformanceWorkflowCurrentStageAPI,
	getGoalConversationAlert,
	exportWorkflowReversalAPI,
	goalReviewWorkFlowListAPI,
	goalReviewAssignUserListAPI,
	savePerformanceGoalReviewCycleAPI,
	getPerformanceGoalReviewCycleAPI,
	getSelfReviewListAPI,
	saveGoalReviewSaveAPI,
	getRatingDropdownDataAPI,
	getTeamReviewUserListAPI,
	deleteGoalReviewCycleAPI,
	getPermissionSearch,
	savePermissionSearch,
	getSystemPermissionData,
	getCategoryAdditionalSearch,
	updatePerformanceGoalReviewCycleAPI,
	saveFinalRatingReview,
	getFinalRatingReviewAPI,
	getFinalRatingLogAPI,
	saveFinalReconsider,
	reviewApproveBulkAPI,
	reviewReconsiderBulkAPI,
	getReviewWorkflowReversalCycleListAPI,
	searchReviewWorkflowOngoingAPI,
	saveReviewWorkflowReversalAPI,
	saveReviewWorkflowCurrentStageAPI,
	exportGoalReviewCycleAPI,
	exportReviewWorkflowReversalAPI,
} from '../services/adminSettingsService';
import {
	PERFORMANCE_REVIEW_ROLE_LIST_FAILED,
	PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS,
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS,
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED,
	UPDATE_COMPETENCY_RATING_TAB_VALUE,
	CURRENT_TAB_SELECTION,
	QUESTION_TYPE_MASTER_SUCCESS,
	QUESTION_TYPE_MASTER_FAILED,
	MANAGE_SCALE_LIST_SUCCESS,
	MANAGE_SCALE_LIST_FAILED,
	HIGHLIGHT_GOAL_FORM_ID,
	RELOAD_GOAL_FORM_LIST,
	ALIGN_GOAL_PAGINATION_DATA,
	HIGHLIGHT_GOAL_CYCLE_ID,
	ALIGN_GOAL_CYCLE_PAGINATION_DATA,
	ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA,
	GOAL_REVIEW_PAGINATION_DATA,
	SET_GOAL_CYCLE_FORM,
	MANAGE_GOAL_TAB_COUNT_SUCCESS,
	MANAGE_GOAL_TAB_COUNT_FAILED,
	GET_PERFORMANCE_GOAL_CYCLE_SUCCESS,
	GET_PERFORMANCE_GOAL_CYCLE_FAILED,
	GET_PERFORMANCE_GOAL_CYCLE_DATA,
	SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT,
	GOAL_CONVERSATION_ALERT_SUCCESS,
	GOAL_CONVERSATION_ALERT_FAILED,
	REFRESH_GOAL_REVIEW_LIST,
	HIGHLIGHT_GOAL_REVIEW_CYCLE_ID,
	SET_PERFORMANCE_REVIEW_CYCLE,
	GET_PERFORMANCE_REVIEW_PENDING,
	GET_PERFORMANCE_REVIEW_SUCCESS,
	GET_PERFORMANCE_REVIEW_FAILED,
	SET_PERFORMANCE_REVIEW_DRUSERDATA,
	SET_PERFORMANCE_SELF_REVIEW_DRUSERDATA,
} from './actionTypes';
const searchPending = () => ({
	type: actionTypes.SEARCH_PENDING,
});
export const getPerformanceCycleList = (data) => {
	return () => {
		return performanceCycleListAPI(data);
	};
};

export const getPerformanceGoalList = (data) => {
	return () => {
		return getPerformanceGoalListAPI(data);
	};
};
export const getSelfReviewList = (data) => {
	return () => {
		return getSelfReviewListAPI(data);
	};
};
export const getPerformanceGoalResponseLog = (data) => {
	return () => {
		return getPerformanceGoalResponseListAPI(data);
	};
};
export const getFinalRatingLog = (data) => {
	return () => {
		return getFinalRatingLogAPI(data);
	};
};
export const getPerformanceGoalDocument = (data) => {
	return () => {
		return getPerformanceGoalAttachmentAPI(data);
	};
};
export const getPerformanceGoalTeamDetails = (data) => {
	return () => {
		return getPerformanceGoalTeamDetailsAPI(data);
	};
};
export const getGoalCycleSelectorApi = (data) => {
	return (dispatch) => {
		return getGoalCycleSelector(data ? data : '')
			.then((response) => {
				if (response.status === 200) {
					response.data.entityList = response.data.entityList.map((data) => {
						var d1 = new Date();
						d1.setHours(0, 0, 0, 0);
						var d2 = new Date(data.reviewDate);
						d2.setHours(23, 59, 59, 999);
						if (d2.getTime() < d1.getTime()) {
							data.isCycleCompleted = true;
						}
						return data;
					});
					dispatch(getPerfomanceGoalCycleCompleted(response.data?.entityList));
				} else {
					dispatch(getPerfomanceGoalCycleFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getPerfomanceGoalCycleFailed(error));
			});
	};
};

const getPerfomanceGoalCycleCompleted = (payload) => ({
	type: GET_PERFORMANCE_GOAL_CYCLE_SUCCESS,
	payload,
});
const getPerfomanceGoalCycleFailed = (error) => ({
	type: GET_PERFORMANCE_GOAL_CYCLE_FAILED,
	payload: error,
});
export const setPerformaceGoalCurrentCycle = (payload) => ({
	type: GET_PERFORMANCE_GOAL_CYCLE_DATA,
	payload,
});

export const getPreviewFormData = (data) => {
	return () => {
		return performPreviewForm(data);
	};
};

export const addPerformanceCycle = (data) => {
	return () => {
		return addPerformanceCycleAPI(data);
	};
};

export const updatePerformanceCycle = (data) => {
	return () => {
		return updatePerformanceCycleAPI(data);
	};
};

export const publishPerformanceCycle = (data, queryParams) => {
	return () => {
		return publishPerformanceCycleAPI(data, queryParams);
	};
};

export const deletePerformanceCycle = (data) => {
	return () => {
		return deletePerformanceCycleAPI(data);
	};
};

export const performanceCycleLinkedForm = (data) => {
	return () => {
		return performanceCycleLinkedFormAPI(data);
	};
};

export const performanceCycleCycle = (data) => {
	return () => {
		return performanceCycleCycleAPI(data);
	};
};

export const performanceCycleActiveCycle = (data) => {
	return () => {
		return performanceCycleActiveCycleAPI(data);
	};
};

export const userVersionUpdateCompleted = (payload) => ({
	type: PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS,
	payload,
});
const userVersionUpdateFailed = (error) => ({
	type: PERFORMANCE_REVIEW_ROLE_LIST_FAILED,
	payload: error,
});

export const performanceRoleAssigned = (data) => {
	return (dispatch) => {
		return performanceRoleAssignedAPI(data)
			.then((response) => {
				if (response.status === Enums.STATUS_SUCCESS) {
					dispatch(userVersionUpdateCompleted(response?.data));
				} else {
					dispatch(userVersionUpdateFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(userVersionUpdateFailed(error));
			});
		// return performanceRoleAssignedAPI(data);
	};
};

const performanceRoleAssignedForCodeValueCompleted = (payload) => ({
	type: PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS,
	payload,
});
const performanceRoleAssignedForCodeValueFailed = (error) => ({
	type: PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED,
	payload: error,
});

export const performanceRoleAssignedForCodeValue = (data) => {
	return (dispatch) => {
		return performanceRoleAssignedAPI(data)
			.then((response) => {
				if (response.status === Enums.STATUS_SUCCESS) {
					dispatch(performanceRoleAssignedForCodeValueCompleted(response?.data));
				} else {
					dispatch(performanceRoleAssignedForCodeValueFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(performanceRoleAssignedForCodeValueFailed(error));
			});
		// return performanceRoleAssignedAPI(data);
	};
};

export const performanceCycleExcludeEmployeeList = (data) => {
	return () => {
		return performanceCycleExcludeEmployeeListAPI(data);
	};
};

export const performanceCycleIncludeEmployee = (data) => {
	return () => {
		return performanceCycleIncludeEmployeeAPI(data);
	};
};

export const performanceRoleDetail = (data) => {
	return () => {
		return performanceRoleDetailAPI(data);
	};
};

export const userDepartmentList = (data) => {
	return () => {
		return userDepartmentListAPI(data);
	};
};

export const ratingFormList = (data) => {
	return () => {
		return ratingFormListAPI(data);
	};
};
export const goalFormList = (data) => {
	return () => {
		return goalFormListAPI(data);
	};
};

export const workflowReversalList = (data) => {
	return () => {
		return workflowReversalListAPI(data);
	};
};

export const reviewWorkflowReversalList = (data) => {
	return () => {
		return reviewWorkflowReversalListAPI(data);
	};
};

export const goalReviewList = (data) => {
	return () => {
		return goalReviewListAPI(data);
	};
};

export const cycleDateOverlap = (data) => {
	return () => {
		return cycleDateOverlapAPI(data);
	};
};

export const ratingFormCreate = (data) => {
	return () => {
		return ratingFormCreateAPI(data);
	};
};

export const ratingFormUpdate = (data) => {
	return () => {
		return ratingFormUpdateAPI(data);
	};
};

export const ratingFormDelete = (data) => {
	return () => {
		return ratingFormDeleteAPI(data);
	};
};

export const ratingFormDetailsDelete = (data) => {
	return () => {
		return ratingFormDetailsDeleteAPI(data);
	};
};

export const isNewPerformanceRole = (data) => {
	return () => {
		return isNewPerformanceRoleAPI(data);
	};
};

export const updateCompetencyRatingSettingTabValue = (payload) => ({
	type: UPDATE_COMPETENCY_RATING_TAB_VALUE,
	payload,
});

export const updateCurrenTabSelection = (payload) => ({
	type: CURRENT_TAB_SELECTION,
	payload,
});
/**
 * Manage Scale Action
 */
const questionTypeMasterDataCompleted = (payload) => ({
	type: QUESTION_TYPE_MASTER_SUCCESS,
	payload,
});
const questionTypeMasterDataFailed = (error) => ({
	type: QUESTION_TYPE_MASTER_FAILED,
	payload: error,
});
export const getQuestionTypeMasterData = (payload) => {
	return (dispatch) => {
		return getQuestionTypeMasterDataAPI(payload)
			.then((response) => {
				if (response.status === Enums.STATUS_SUCCESS) {
					dispatch(questionTypeMasterDataCompleted(response?.data?.entityList));
				} else {
					dispatch(questionTypeMasterDataFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(questionTypeMasterDataFailed(error));
			});
	};
};
const getManageScaleListWIthReduxStateCompleted = (payload) => ({
	type: MANAGE_SCALE_LIST_SUCCESS,
	payload,
});
const getManageScaleListWIthReduxStateFailed = (error) => ({
	type: MANAGE_SCALE_LIST_FAILED,
	payload: error,
});
export const getManageScaleListWIthReduxState = (payload) => {
	return (dispatch) => {
		return getManageScaleListAPI(payload)
			.then((response) => {
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data;
					const { records } = entity || {};
					dispatch(getManageScaleListWIthReduxStateCompleted(records && records?.length ? records : []));
				} else {
					dispatch(getManageScaleListWIthReduxStateFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getManageScaleListWIthReduxStateFailed(error));
			});
	};
};

export const getManageScaleList = (payload) => {
	return () => {
		return getManageScaleListAPI(payload);
	};
};
export const addScaleDetails = (payload) => {
	return () => {
		return addScaleDetailsAPI(payload);
	};
};
export const updateScaleDetails = (payload) => {
	return () => {
		return updateScaleDetailsAPI(payload);
	};
};
export const getPerformanceTabCount = (payload) => {
	return () => {
		return getPerformanceTabCountAPI(payload);
	};
};
export const updateManageScaleStatus = (payload, queryParams) => {
	return () => {
		return updateManageScaleStatusAPI(payload, queryParams);
	};
};
export const deleteManageScale = (payload) => {
	return () => {
		return deleteManageScaleAPI(payload);
	};
};
export const goalCycleList = (data) => {
	return () => {
		return goalCycleListAPI(data);
	};
};
export const goalWorkflowListApi = (data) => {
	return () => {
		return goalReviewWorkFlowListAPI(data);
	};
};
export const goalWorkflowAssignUserListApi = (data) => {
	return () => {
		return goalReviewAssignUserListAPI(data);
	};
};
export const goalCycleRecordList = (data) => {
	return () => {
		return goalCycleRecordListAPI(data);
	};
};
export const getWorkflowReversalCycleList = (data) => {
	return () => {
		return getWorkflowReversalCycleListAPI(data);
	};
};
export const getReviewWorkflowReversalCycleList = (data) => {
	return () => {
		return getReviewWorkflowReversalCycleListAPI(data);
	};
};
const getGoalConversationAlertSuccess = (payload) => ({
	type: GOAL_CONVERSATION_ALERT_SUCCESS,
	payload,
});
const getGoalConversationAlertFailed = (error) => ({
	type: GOAL_CONVERSATION_ALERT_FAILED,
	payload: error,
});
export const getGoalAlertConversation = (data) => {
	return (dispatch) => {
		return getGoalConversationAlert(data)
			.then((response) => {
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data;
					dispatch(getGoalConversationAlertSuccess(entity));
				} else {
					dispatch(getGoalConversationAlertFailed({}));
				}
			})
			.catch((error) => {
				dispatch(getPerformanceGoalTabCountFailed({}));
			});
	};
};
export const goalCycleDetails = (data) => {
	return () => {
		return goalCycleDetailsAPI(data);
	};
};
export const addGoalCycle = (data) => {
	return () => {
		return addGoalCycleAPI(data);
	};
};
export const isTimeframeOverlap = (data) => {
	return () => {
		return isTimeframeOverlapAPI(data);
	};
};
export const updateGoalCycle = (data) => {
	return () => {
		return updateGoalCycleAPI(data);
	};
};
export const deleteGoalCycle = (data) => {
	return () => {
		return deleteGoalCycleAPI(data);
	};
};
export const assignUserSearchByName = (data) => {
	return () => {
		return assignUserSearchByNameAPI(data);
	};
};
export const validateManualAssignedUser = (data) => {
	return () => {
		return validateManualAssignedUserAPI(data);
	};
};

export const validateBulkAssignedUser = (data) => {
	return () => {
		return validateBulkAssignedUserAPI(data);
	};
};

const getPerformanceGoalTabCountSuccess = (payload) => ({
	type: MANAGE_GOAL_TAB_COUNT_SUCCESS,
	payload,
});
const getPerformanceGoalTabCountFailed = (error) => ({
	type: MANAGE_GOAL_TAB_COUNT_FAILED,
	payload: error,
});
export const getPerformanceGoalTabCount = (data) => {
	return (dispatch) => {
		return getPerformanceGoalTabCountAPI(data)
			.then((response) => {
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data;
					dispatch(getPerformanceGoalTabCountSuccess(entity));
				} else {
					dispatch(getPerformanceGoalTabCountFailed({}));
				}
			})
			.catch((error) => {
				dispatch(getPerformanceGoalTabCountFailed({}));
			});
	};
};
export const postPerformanceGoalForm = (payload) => {
	return () => {
		return savePerformanceGoalFormAPI(payload);
	};
};
export const saveUserGoalApi = (payload) => {
	return () => {
		return savePerformanceGoalUserAPI(payload);
	};
};
export const saveGoalReviewSavedApi = (payload) => {
	return () => {
		return saveGoalReviewSaveAPI(payload);
	};
};
export const getRatingDropdownData = (payload) => {
	return () => {
		return getRatingDropdownDataAPI(payload);
	};
};
export const saveFinalRatingReviews = (payload) => {
	return () => {
		return saveFinalRatingReview(payload);
	};
};
export const getFinalRatingReview = (payload) => {
	return () => {
		return getFinalRatingReviewAPI(payload);
	};
};
export const getTeamReviewUserList = (payload) => {
	return () => {
		return getTeamReviewUserListAPI(payload);
	};
};

export const getSystemPermissionSearch = (searchStr, page, pageSize, type) => {
	return (dispatch) => {
		dispatch(searchPending());
		return getPermissionSearch(searchStr, page, pageSize, type);
	};
};

export const saveFeedbackPermission = (data) => {
	return (dispatch) => {
		return savePermissionSearch(data);
	};
};
export const getCategoryPermissionSearch = (searchStr, page, pageSize, type) => {
	return (dispatch) => {
		dispatch(searchPending());
		return getCategoryAdditionalSearch(searchStr, page, pageSize, type);
	};
};

export const getSystemPermissionAdminData = (payload) => {
	return () => {
		return getSystemPermissionData(payload);
	};
};
export const deleteGoalForm = (payload) => {
	return () => {
		return deleteGoalFormAPI(payload);
	};
};
export const saveFinalReconsiderApi = (payload) => {
	return () => {
		return saveFinalReconsider(payload);
	};
};
export const putPerformanceGoalForm = (payload) => {
	return () => {
		return updatePerformanceGoalFormAPI(payload);
	};
};

export const getPerformanceGoalResponseType = (payload) => {
	return () => {
		return getPerformanceGoalResponseTypeAPI(payload);
	};
};

export const exportGoalFormListReport = (data) => {
	return () => {
		return exportGoalFormAPI(data);
	};
};

export const exportGoalCycleListReport = (data) => {
	return () => {
		return exportGoalCycleAPI(data);
	};
};

export const exportGoalReviewCycleListReport = (data) => {
	return () => {
		return exportGoalReviewCycleAPI(data);
	};
};

export const exportWorkflowReversalReport = (data) => {
	return () => {
		return exportWorkflowReversalAPI(data);
	};
};
export const exportReviewWorkflowReversalReport = (data) => {
	return () => {
		return exportReviewWorkflowReversalAPI(data);
	};
};
export const exportEachGoalFormReport = (data) => {
	return () => {
		return exportEachGoalFormAPI(data);
	};
};

export const performanceGoalFormDeleteReport = (data) => {
	return () => {
		return performanceGoalFormDeleteReportAPI(data);
	};
};

export const performanceGoalFormDownloadStatusReport = (data) => {
	return () => {
		return performanceGoalFormDownloadStatusReportAPI(data);
	};
};

export const performanceGoalSettingReversalDownloadReport = (data) => {
	return () => {
		return performanceGoalSettingReversalDownloadReportAPI(data);
	};
};

export const setHighlightGoalFormId = (payload) => ({
	type: HIGHLIGHT_GOAL_FORM_ID,
	payload,
});

export const reloadGoalFormList = (payload) => ({
	type: RELOAD_GOAL_FORM_LIST,
	payload,
});

export const setPaginationData = (payload) => ({
	type: ALIGN_GOAL_PAGINATION_DATA,
	payload,
});

export const setHighlightGoalCycleId = (payload) => ({
	type: HIGHLIGHT_GOAL_CYCLE_ID,
	payload,
});

export const setHighlightGoalReviewCycleId = (payload) => ({
	type: HIGHLIGHT_GOAL_REVIEW_CYCLE_ID,
	payload,
});

export const refreshGoalReviewReviewList = (payload) => ({
	type: REFRESH_GOAL_REVIEW_LIST,
	payload,
});

export const setGoalCyclePaginationData = (payload) => ({
	type: ALIGN_GOAL_CYCLE_PAGINATION_DATA,
	payload,
});

export const setGoalCycleWorkflowPaginationData = (payload) => ({
	type: ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA,
	payload,
});

export const setGoalReviewPaginationData = (payload) => ({
	type: GOAL_REVIEW_PAGINATION_DATA,
	payload,
});

export const setGoalFormCount = (payload) => ({
	type: SET_GOAL_CYCLE_FORM,
	payload,
});

export const setPerfomanceGoalAttachmentCount = (payload) => ({
	type: SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT,
	payload,
});

export const validateBulkUploadGoalForm = (data) => {
	return () => {
		return validateBulkUploadGoalFormApi(data);
	};
};

export const bulkUploadGoalForm = (data) => {
	return () => {
		return bulkUploadGoalFormApi(data);
	};
};

export const savePerformanceWorkflow = (data) => {
	return () => {
		return savePerformanceWorkflowAPI(data);
	};
};

export const saveReviewWorkflowReversal = (data) => {
	return () => {
		return saveReviewWorkflowReversalAPI(data);
	};
};

export const searchOngoingEmployee = (data) => {
	return () => {
		return searchOngoingApi(data);
	};
};
export const searchReviewWorkflowOngoingEmployee = (data) => {
	return () => {
		return searchReviewWorkflowOngoingAPI(data);
	};
};
export const savePerformanceWorkflowCurrentStage = (data) => {
	return () => {
		return savePerformanceWorkflowCurrentStageAPI(data);
	};
};
export const saveReviewWorkflowCurrentStage = (data) => {
	return () => {
		return saveReviewWorkflowCurrentStageAPI(data);
	};
};
export const savePerformanceGoalReviewCycle = (data) => {
	return () => {
		return savePerformanceGoalReviewCycleAPI(data);
	};
};
export const updatePerformanceGoalReviewCycle = (data) => {
	return () => {
		return updatePerformanceGoalReviewCycleAPI(data);
	};
};
export const deleteGoalReviewCycle = (data) => {
	return () => {
		return deleteGoalReviewCycleAPI(data);
	};
};
export const setGoalReviewCurrentCycle = (payload) => ({
	type: SET_PERFORMANCE_REVIEW_CYCLE,
	payload,
});
export const setPerformanceReviewDrUserData = (payload) => ({
	type: SET_PERFORMANCE_REVIEW_DRUSERDATA,
	payload,
});
const getPerformanceGoalReviewCyclePending = () => ({
	type: GET_PERFORMANCE_REVIEW_PENDING,
});
const getPerformanceGoalReviewCycleSuccess = (payload) => ({
	type: GET_PERFORMANCE_REVIEW_SUCCESS,
	payload,
});

const getPerformanceGoalReviewCycleFailed = (error) => ({
	type: GET_PERFORMANCE_REVIEW_FAILED,
	payload: error,
});
export const getPerformanceGoalReviewCycle = (data) => {
	return (dispatch) => {
		dispatch(getPerformanceGoalReviewCyclePending());
		return getPerformanceGoalReviewCycleAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getPerformanceGoalReviewCycleSuccess(response.data));
				} else {
					dispatch(getPerformanceGoalReviewCycleFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getPerformanceGoalReviewCycleFailed(error));
			});
	};
};

export const setPerformanceSelfReviewDrUserData = (payload) => ({
	type: SET_PERFORMANCE_SELF_REVIEW_DRUSERDATA,
	payload,
});

export const reviewApproveBulk = (payload) => {
	return () => {
		return reviewApproveBulkAPI(payload);
	};
};

export const reviewReconsiderBulk = (payload) => {
	return () => {
		return reviewReconsiderBulkAPI(payload);
	};
};
