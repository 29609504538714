import React, { Fragment, useState } from 'react';
import {
	Box,
	Avatar,
	Typography,
	Grow,
	Tooltip,
	Popper,
	Paper,
	ClickAwayListener,
	MenuItem,
	ListItemText,
	ListItemAvatar,
	MenuList,
	Chip,
} from '@material-ui/core';
import { setLocalStorageItem } from '../../../services/StorageService';
import { HOME, PEOPLEMAPS, USER_VIEW } from '../../../config/app-url';
import {
	formatDate,
	formatFeedbackDate,
	getMonthDateYearTime,
	getRandomBackgroundColor,
	getRandomColor,
	getUserName,
} from '../../../config/utils';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import ProgressLabel from '../../Common/ProgressLabel';
import { ProgressBar } from '../../Common/ProgressBar';
import { KeyResultIcon, ObjectiveIcon } from '../../../config/svg/MyGoalSvg';
import { Enums } from '../../../config/enums';

export const PeopleProgressCard: React.FC<any> = (props) => {
	const { data, t, panelUsed, alignmentmapTab, cardId, handleOnClickPerformanceSnapshot = () => {} } = props;
	const directData = data.data ? data.data : data;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const contributorDetails = directData.contributorsLastSevenDaysProgress;
	const [okranchorEl, setOkrAnchorEl] = useState<any>(null);
	const [okropen, okrsetOpen] = useState<boolean>(false);
	const [scaleValue, setScaleValue] = React.useState<any>(1);
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [anchorElTeam, setAnchorElTeam] = useState<HTMLElement | null>(null);
	/**
	 * Method to redirect user to people view when click on card
	 * @param e eventObject
	 * @param employeeObj Object
	 */
	const handleCardClick = async (e: any, directData: any) => {
		const employeeId = directData.employeeId;
		e.stopPropagation();
		e.preventDefault();
		window.scroll(0, 150);
		const dataObj = 'empId=' + employeeId;
		const resp = await props.getUserById(dataObj);
		if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: panelUsed || HOME,
					tabToOpen: 'directReport',
				})
			);

			if (panelUsed === '/alignment-maps') {
				setLocalStorageItem('currentUser', JSON.stringify(directData));
				props.history.push(PEOPLEMAPS);
				props.updateRoute(PEOPLEMAPS);
			} else {
				if (resp.data.entityList) {
					setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
				}
				props.resetOkr();
				props.history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			}
		}
	};

	const handleProgressOpen = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setOpen(true);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const handleOpen = (event: any) => {
		setOkrAnchorEl(event.currentTarget);
		okrsetOpen(true);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const handleClose = () => {
		okrsetOpen(false);
		setOkrAnchorEl(null);
	};
	const handleOpenTeam = (event: any) => {
		setAnchorElTeam(event.currentTarget);
		setOpenTeamMenu(true);
	};
	const handleCloseTeam = (event: any) => {
		setAnchorElTeam(null);
		setOpenTeamMenu(false);
	};

	return (
		<Fragment>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box
					id={cardId || ''}
					onClick={(e) => {
						handleCardClick(e, directData);
					}}
					className='people-progress-card'
				>
					<Box className='people-progress-card-top'>
						<Box className='user-info v-center'>
							<Box className='user-img'>
								{directData?.imagePath ? (
									<Avatar
										className='avatar-default'
										alt={`${directData?.firstName} ${directData?.lastName}`}
										src={directData?.imagePath}
									/>
								) : (
									<Avatar className='avatar-default'>
										{getUserName({
											firstName: directData?.firstName,
											lastName: directData?.lastName,
											fullName: directData?.fullname,
										})}
									</Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								<Box className='user-info-label'>
									<Typography>{`${directData?.firstName} ${directData?.lastName}`}</Typography>
								</Box>
							</Box>
						</Box>
						<Box className='people-progress-card-actions'>
							{props.panelUsed && props?.userSelectedCycle?.cycleStatus !== Enums.PAST_CYCLE_ID && (
								<Box
									onClick={(e: any) => {
										e.stopPropagation();
									}}
								>
									<OkrPopperMenu
										poperClassName={
											alignmentmapTab
												? 'alignment-popper-menu popper-menu-list okr-list-popper-menu alignment-direct-menu'
												: 'alignment-popper-menu popper-menu-list okr-list-popper-menu dashboard-direct-menu'
										}
										{...props}
										okr={directData}
										directReports={true}
										panelUsed={props.panelUsed}
										t={t}
										poperPlacement={'bottom-end'}
										poperContainer={false}
										showGiveFeedback={1}
										diagramInstance={props.diagramInstance}
										handleOnClickPerformanceSnapshot={handleOnClickPerformanceSnapshot}
									/>
								</Box>
							)}
						</Box>
					</Box>
					<Box className='people-progress-mid'>
						<Box className='people-progress-slider'>
							<ProgressBar score={directData.score} t={t} />
							<Box className='progress-update-txt'>
								{directData.isLastProgressDateType ? (
									<Typography variant='h6'>
										{t('lastpProgressOn')}
										{getMonthDateYearTime(directData.lastProgressDate)}
									</Typography>
								) : (
									<Typography variant='h6'>
										{t('lastpProgressOn')}
										{directData.lastProgressDate}
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
					<Box className='people-progress-bottom'>
						<Box className='people-okr-count'>
							<Box className='people-obj-count'>
								<ObjectiveIcon />
								<Typography variant='h6'>{`${directData.okrCount} ${t('objectives')}`}</Typography>
							</Box>
							<Box className='people-kr-count'>
								<KeyResultIcon />
								<Typography variant='h6'>{`${directData.krCount} ${t('keyResults')}`}</Typography>
							</Box>
						</Box>
						<Box className='user-team-lists'>
							{directData?.employeeTeams.length > 0 &&
								directData?.employeeTeams.map(
									(item: any, index: number) =>
										index === 0 && (
											<Chip
												style={{
													backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
													color: getRandomBackgroundColor(item?.colorcode),
												}}
												className='user-team-chip'
												id={`user-team-chip-${index}`}
												avatar={
													item?.logoImagePath ? (
														<Avatar className='avatar-default' alt={item?.teamName} src={item?.logoImagePath} />
													) : (
														<Avatar
															style={{
																backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																color: getRandomBackgroundColor(item?.colorcode),
															}}
														>
															{getUserName({
																firstName: '',
																lastName: '',
																fullName: item?.teamName,
															})}
														</Avatar>
													)
												}
												label={
													item?.teamName?.length > 10 ? (
														<Tooltip title={item?.teamName} arrow>
															<span>{item?.teamName.substring(0, 10)}...</span>
														</Tooltip>
													) : (
														item?.teamName
													)
												}
											/>
										)
								)}
							{directData?.employeeTeams.length > 1 && (
								<Box
									className='other-user-team-chip'
									aria-owns={openTeamMenu ? 'mouse-over-popover' : undefined}
									aria-haspopup='true'
									onMouseOver={handleOpenTeam}
									onMouseLeave={handleCloseTeam}
								>
									<Typography variant='body2'>{`${directData?.employeeTeams.length - 1}`}</Typography>
									<Popper
										className='popper-menu-list team-filter-list-menu'
										transition
										open={openTeamMenu}
										anchorEl={anchorElTeam}
										placement='bottom-start'
									>
										<Paper>
											<ClickAwayListener onClickAway={handleCloseTeam}>
												<MenuList id='direct-report-team-list'>
													{directData?.employeeTeams.length > 0 &&
														directData?.employeeTeams.map((item: any, index: number) => (
															<>
																{index > 0 && (
																	<MenuItem key={item?.teamId} id={`team-name-${directData?.organizationName}`}>
																		<ListItemAvatar>
																			<Avatar
																				className='team-avatar-small'
																				style={{
																					backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																					color: getRandomBackgroundColor(item?.colorCode),
																				}}
																				//src={team?.imagePath}
																			>
																				{getUserName({
																					firstName: '',
																					lastName: '',
																					fullName: item?.teamName,
																				})}
																			</Avatar>
																		</ListItemAvatar>
																		<ListItemText
																			primary={
																				<Typography variant='h5' className='team-filter-txt'>
																					{item?.teamName && item?.teamName.length > 12 ? (
																						<Tooltip arrow title={item?.teamName} aria-label='Teams'>
																							<Typography>{item?.teamName.substring(0, 12)}...</Typography>
																						</Tooltip>
																					) : (
																						<Typography>{item?.teamName}</Typography>
																					)}
																				</Typography>
																			}
																		/>
																		<ProgressLabel percentage={item} />
																	</MenuItem>
																)}
															</>
														))}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Popper>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Grow>
		</Fragment>
	);
};
