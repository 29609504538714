import React, { useEffect, useState } from 'react';
import { Avatar, Box, ClickAwayListener, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
	AcceptIcon,
	BottomArrowIcon,
	FinalRatingIcon2,
	RecommendedIcon,
	SalaryIcon,
} from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import { Enums } from '../../config/enums';
import { NoUnitsIcon } from '../../config/svg/MyGoalSvg';
import { CommentIcon, ConversationIcon, DesignationIcon, ResponseLogIcon } from '../../config/svg/CommonSvg';
import CkEditor from '../Common/CkEditor/CkEditor';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFinalRatingReview,
	getGoalAlertConversation,
	getRatingDropdownData,
	saveFinalRatingReviews,
	saveFinalReconsiderApi,
	setPerformanceReviewDrUserData,
} from '../../action/adminSettings';
import { formatFeedbackDate, getFullName, getUserName } from '../../config/utils';
import { saveFinalRatingReview } from '../../services/adminSettingsService';
import { useSnackbar } from 'notistack';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { ReviewConversationsSettings } from './ReviewConversationsSettings';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { TeamReviewFinalRating } from './TeamReviewFinalRating';
import { TeamReviewTrainings } from './TeamReviewTrainings';
import { TeamReviewTrainingsSearch } from './TeamReviewTrainingsSearch';
import { getEmployeeTrainingData } from '../../action/courses';

export const TrainingData: React.FC<any> = (props) => {
	const { drUserData, users, fromTeamDetails, isDrSelfReviewData, getUserPerformanceGoalsList, isEditable } = props;

	const { t } = useTranslation();
	const moment = extendMoment(Moment);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 18);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	const [addMoreClicked, setAddMoreClicked] = useState<Boolean>(false);
	const [trainingDataFinal, setTrainingDataFinal] = useState<any>([]);
	const finalRatingDataContent = {
		performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
		oneonOneDate: '',
		eligibleForSalaryIncrement: true,
		salaryIncrement: '',
		eligibleForPromotion: true,
		promotionDesignation: '',
		finalComment: '',
		changedFinalRatingId: 0,
		status: 2,
		assignTrainingRequest: {
			performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
			employeeId: drUserData.employeeId,
			reportingToId: drUserData.reportingTo,
			loggedIdUserId: drUserData.reportingTo,
			trainingIds: [], //[trainingDataFinal.map((item: any) => item.id)],
			removeTrainingIds: [],
		},
		otherTrainings: '',
		justificationToChangeTheRating: '',
	};
	const finalRatingDataContentError = {
		oneonOneDate: '',
		eligibleForSalaryIncrement: '',
		salaryIncrement: '',
		eligibleForPromotion: '',
		promotionDesignation: '',
		finalComment: '',
		changedFinalRatingId: '',
		justificationToChangeTheRating: '',
	};
	const { enqueueSnackbar } = useSnackbar();

	const [finalRatingData, setFinalRatingData] = useState<any>({ ...finalRatingDataContent });
	const [finalRatingDataError, setFinalRatingDataError] = useState<any>({ ...finalRatingDataContentError });
	const [selectedKrDateRange, setSelectedKrDateRange] = useState<any>(null);
	const [ratingDropdownData, setRatingDropdownData] = useState<any[]>([]);
	const dispatch = useDispatch();
	const [trainingEmployeeData, setTrainingEmployeeData] = useState<any>([]);
	const defaultPagingInfo: any = {
		pageIndex: 0,
		pageSize: 16,
	};
	const [pagingInfo, setPagingInfo] = useState<any>({ ...defaultPagingInfo });

	useEffect(() => {
		loadRatingDropdownData('');
		getTrainingCoursesData();
	}, []);
	const getTrainingCoursesData = async () => {
		const payload: any = {
			performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
			pageIndex: 0,
			pageSize: 10,
			sortingText: '',
			order: '',
			finder: '',
			category_Ids: [],
			course_Ids: [],
			competency: '',
		};
		const trainingListings: any = await dispatch(getEmployeeTrainingData(payload));
		if (trainingListings?.data?.status === 200) {
			let trainingCourses = trainingListings.data.entity.records;
			setTrainingEmployeeData(trainingCourses);
			setTrainingDataFinal([...trainingCourses]);
		}
	};
	const loadRatingDropdownData = async (requestPayload: any) => {
		try {
			const response: any = await dispatch(getRatingDropdownData(requestPayload));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setRatingDropdownData(response.data.entityList);
				loadFinalRatingData(response.data.entityList);
			} else {
			}
		} catch (error) {}
	};
	const loadFinalRatingData = async (ratingDropdownList = []) => {
		try {
			const response: any = await dispatch(
				getFinalRatingReview(`PerformanceGoalRatingAssessmentId=${drUserData.performanceGoalRatingAssessmentId}`)
			);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				let selectedRating: any[] =
					response.data.entity?.changedFinalRatingId && ratingDropdownList && ratingDropdownList.length > 0
						? ratingDropdownList.filter((item: any) => item.id === response.data.entity.changedFinalRatingId)
						: [];
				let ratingDataObject = {
					...response.data.entity,
					assignTrainingRequest: {
						performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
						employeeId: drUserData.employeeId,
						reportingToId: drUserData.reportingTo,
						loggedIdUserId: drUserData.reportingTo,
						trainingIds: [],
						removeTrainingIds: [],
					},
				};
				if (selectedRating && selectedRating.length > 0) {
					ratingDataObject.name = selectedRating[0].name;
				}
				if (ratingDataObject.eligibleForSalaryIncrement === null) {
					ratingDataObject.eligibleForSalaryIncrement = false;
				}
				if (ratingDataObject.eligibleForPromotion === null) {
					ratingDataObject.eligibleForPromotion = false;
				}
				if (ratingDataObject.justificationToChangeTheRating !== '') {
					ratingDataObject.isDropDownChanged = true;
				}

				if (response.data.entity.oneonOneDate) {
					setSelectedKrDateRange(moment(new Date(response.data.entity.oneonOneDate)));
				}
				setFinalRatingData(ratingDataObject);
			} else {
			}
		} catch (error) {}
	};
	const handleAddMoreTraining = () => {
		if (isEditable) {
			setAddMoreClicked(true);
		}
	};
	const handleAddMoreTrainingCancel = () => {
		setAddMoreClicked(false);
	};
	const selectCourses = (data: any) => {
		const keys = Object.keys(data);
		const trainingData = keys.map((item) => data[item]);
		setTrainingDataFinal([...trainingDataFinal, ...trainingData]);
		setAddMoreClicked(false);
	};
	const validateForm = (isDraft = false) => {
		let isError = false;
		let errorObject: any = { ...finalRatingDataContentError };
		if (!finalRatingData.changedFinalRatingId) {
			errorObject.changedFinalRatingId = 'Please select rating';
			isError = true;
		}
		if (!finalRatingData.oneonOneDate) {
			errorObject.oneonOneDate = 'Please select 1-on-1 date';
			isError = true;
		}
		if (!finalRatingData.salaryIncrement && finalRatingData.eligibleForSalaryIncrement === true) {
			errorObject.salaryIncrement = 'Please enter increment value';
			isError = true;
		}
		if (!finalRatingData.promotionDesignation && finalRatingData.eligibleForPromotion === true) {
			errorObject.promotionDesignation = 'Please enter designation';
			isError = true;
		}
		if (!finalRatingData.finalComment) {
			errorObject.finalComment = 'Please enter final comments';
			isError = true;
		}
		if (!finalRatingData.otherTrainings && trainingDataFinal.length === 0) {
			errorObject.otherTrainings = 'Please enter final comments';
			isError = true;
		}
		if (finalRatingData.isDropDownChanged && finalRatingData.justificationToChangeTheRating.trim() === '') {
			errorObject.justificationToChangeTheRating = 'Please select Justification to Change the rating';
			isError = true;
		}
		if (isError && !isDraft) {
			setFinalRatingDataError({ ...errorObject });
		}
		return isError;
	};
	const handleSave = async (isDraft = false, status = 0) => {
		let isError = validateForm(isDraft);
		if (isError && !isDraft) {
			return false;
		}
		let postData: any = {
			...finalRatingData,
			status: status && status !== 0 ? status : isDraft ? 1 : 2,
		};
		if (finalRatingData.oneonOneDate) {
			postData.oneonOneDate = formatFeedbackDate(finalRatingData.oneonOneDate);
		}
		if (trainingDataFinal.length === 0 && trainingEmployeeData.length > 0) {
			postData.assignTrainingRequest.removeTrainingIds = trainingEmployeeData.map((item: any) => item.id);
		} else {
			let originalIds = trainingEmployeeData.map((item: any) => item.id);
			let finalIds = trainingDataFinal.map((item: any) => item.id);
			let removedCourses: any[] = [];
			originalIds.forEach((courseId: any) => {
				if (finalIds.indexOf(courseId) === -1) {
					removedCourses.push(courseId);
				}
			});
			postData.assignTrainingRequest.removeTrainingIds = removedCourses;
			postData.assignTrainingRequest.trainingIds = finalIds;
		}
		let selectedData: any[] = trainingDataFinal.map((item: any) => item.id);
		const response: any = await dispatch(saveFinalRatingReviews(postData));
		if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
			enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			if (!isDraft) {
				getUserPerformanceGoalsList();
				dispatch(setPerformanceReviewDrUserData({}));
			}
			setFinalRatingDataError(finalRatingDataContentError);
			loadFinalRatingData();
			window.scroll({ top: 0, behavior: 'smooth' });
		} else {
			window.scroll({ top: 0, behavior: 'smooth' });
		}
	};

	const handleReconsider = async (isDraft = false, status = 0) => {
		let postData: any = {
			performanceGoalRatingAssessmentId: finalRatingData.performanceGoalRatingAssessmentId,
			performanceGoalReviewWorkFlowTypeId: finalRatingData.performanceGoalReviewWorkFlowTypeId,
		};
		const response: any = await dispatch(saveFinalReconsiderApi(postData));
		if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
			enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch(setPerformanceReviewDrUserData({}));
			window.scroll({ top: 0, behavior: 'smooth' });
		} else {
			window.scroll({ top: 0, behavior: 'smooth' });
		}
	};
	return (
		<>
			{addMoreClicked ? (
				<TeamReviewTrainingsSearch
					handleAddMoreTrainingCancel={handleAddMoreTrainingCancel}
					drUserData={drUserData}
					setAddMoreClicked={setAddMoreClicked}
					selectCourses={selectCourses}
					trainingDataFinal={trainingDataFinal}
				/>
			) : (
				<>
					<TeamReviewTrainings
						handleAddMoreTraining={handleAddMoreTraining}
						trainingDataFinal={trainingDataFinal}
						setTrainingDataFinal={setTrainingDataFinal}
						finalRatingData={finalRatingData}
						setFinalRatingData={setFinalRatingData}
						finalRatingDataError={finalRatingDataError}
						isEditable={isEditable}
					/>
					<TeamReviewFinalRating
						drUserData={drUserData}
						fromTeamDetails={fromTeamDetails}
						isDrSelfReviewData={isDrSelfReviewData}
						users={users}
						finalRatingData={finalRatingData}
						setFinalRatingData={setFinalRatingData}
						handleSave={handleSave}
						validateForm={validateForm}
						handleReconsider={handleReconsider}
						finalRatingDataError={finalRatingDataError}
						setFinalRatingDataError={setFinalRatingDataError}
						ratingDropdownData={ratingDropdownData}
						setRatingDropdownData={setRatingDropdownData}
						selectedKrDateRange={selectedKrDateRange}
						setSelectedKrDateRange={setSelectedKrDateRange}
						isEditable={isEditable}
					/>
				</>
			)}
		</>
	);
};
