import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, ClickAwayListener, FormLabel, List, ListItem, Typography } from '@material-ui/core';
import CkEditor from '../../../Common/CkEditor/CkEditor';
import { OKRButton } from '../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../config/svg/CommonSvgIcon';
import { StatusIcon } from '../../../../config/svg/CommonSvg';
import { useDispatch } from 'react-redux';
import { pipResolveStatus } from '../../../../action/performanceImprovementPlan';
import { Enums } from '../../../../config/enums';
import { getGlobalTaskListDueDate, getTime, getUserName } from '../../../../config/utils';
import AlertDialog from '../../../Common/Dialog';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';

export const Resolve = (props: any) => {
	const {
		loader,
		pipDetails,
		setLoader,
		// isUserHavePIPPermission,
		// isUserHavePIPEditPermission,
		// handleBackToListClick,
		// pipFormType,
		handleTabChange,
		getPIPViewDetails,
		resolveStatusTypes,
		showMsg,
		setIsFormEdited,
		userDetails,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const statusDataObj = {
		performanceImprovementPlanId: 0,
		statusComment: '',
		performanceImprovementPlanStatusId: 0,
	};

	const [openStatusDropdown, setOpenStatusDropdown] = React.useState<Boolean>(false);
	const [resolveStatusData, setResolveStatusData] = useState<any>(statusDataObj);
	const [statusSelectionError, setStatusSelectionError] = useState<string>('');
	const [commentError, setCommentError] = useState<string>('');
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });

	const handleFeedbackDropdown = () => {
		setOpenStatusDropdown((prev: Boolean) => !prev);
	};
	const handleFeedbackClickAway = () => {
		setOpenStatusDropdown(false);
	};
	const handleResolveDropdownSelection = (event: any, statusData: any) => {
		handleFeedbackClickAway();
		setResolveStatusData({
			...resolveStatusData,
			performanceImprovementPlanStatusId: statusData?.performanceImprovementPlanStatusId,
			statusName: statusData?.name,
		});
		setStatusSelectionError('');
		setIsFormEdited(true);
	};
	const handleCancelClick = (e: any) => {
		setIsFormEdited(false);
		setResolveStatusData(statusDataObj);
		handleTabChange(e, Enums.ONE);
	};

	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'SubmitForm') {
				resolvePipAPICall();
			}
		}
		setModalProps({ open: false, type: '', message: '' });
	};
	const handleSubmitClick = () => {
		if (resolveStatusData?.performanceImprovementPlanStatusId) {
			if (resolveStatusData?.statusComment) {
				setModalProps({
					open: true,
					type: 'SubmitForm',
					message: t(
						'Once submitted, no changes can be made to the PIP details & submission details. Do you want to continue?'
					),
				});
			} else {
				setCommentError('Comment is required to Resolve PIP');
			}
		} else {
			setStatusSelectionError('Status selection is required to Resolve PIP');
		}
	};
	const resolvePipAPICall = async () => {
		try {
			// if (resolveStatusData?.performanceImprovementPlanStatusId) {
			// 	if (resolveStatusData?.statusComment) {
			setLoader(true);
			const requestPayload = {
				...resolveStatusData,
				performanceImprovementPlanId: pipDetails?.performanceImprovementPlanId || 0,
			};
			delete requestPayload.statusName;
			const response: any = await dispatch(pipResolveStatus(requestPayload));
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('success', messages || `Personal development plan status updated successfully`);
				setResolveStatusData(statusDataObj);
				getPIPViewDetails(pipDetails);
				// handleBackToListClick(pipFormType);
				// setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Error occurred while fetching personal development plan.`);
				setLoader(false);
			}
			setIsFormEdited(false);
			// 	} else {
			// 		setCommentError('Comment is required to Resolve PIP');
			// 	}
			// } else {
			// 	setStatusSelectionError('Status selection is required to Resolve PIP');
			// }
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	//LINE 137, 227
	return (
		<>
			<Box className='full-rectangular-card pip-view-resolve'>
				{pipDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
				pipDetails?.performanceImprovementPlanStatusId === Enums.THREE ? (
					<Box className='resolve-edit-mode'>
						<FormLabel id='link-feedback' component='legend' className='form-label'>
							<StatusIcon />
							{t('statusLabel')}
							<i style={{ color: '#ff0000' }}>*</i>
						</FormLabel>
						<Box
							className={`ques-type-selection custom-type-dropdown-top helperText-bottom ${
								openStatusDropdown ? 'active' : ''
							}`}
						>
							<OKRButton
								className={'dropdown-button button-placeholder'}
								text={resolveStatusData?.statusName || '- Select -'}
								icon={<BottomArrowIcon />}
								handleClick={() => handleFeedbackDropdown()}
							></OKRButton>
							{statusSelectionError ? (
								<Typography className='error-field'>
									<ErrorIcon />
									{statusSelectionError || ''}
								</Typography>
							) : (
								<></>
							)}

							{openStatusDropdown && (
								<ClickAwayListener onClickAway={handleFeedbackClickAway}>
									<Box className='ques-type-dropdown'>
										<List component='div' disablePadding>
											{resolveStatusTypes?.map((item: any) => {
												return (
													<ListItem
														component='div'
														className={
															resolveStatusData?.performanceImprovementPlanStatusId ===
															item?.performanceImprovementPlanStatusId
																? 'active'
																: ''
														}
														key={`resolve_status_${item?.performanceImprovementPlanStatusId}`}
														onClick={(e: any) => handleResolveDropdownSelection(e, item)}
													>
														<Typography>{item?.name || ''}</Typography>
													</ListItem>
												);
											})}
										</List>
									</Box>
								</ClickAwayListener>
							)}
						</Box>
						<Box className='helperText-bottom'>
							<CkEditor
								placeHolderText={t('writeFinalComment')}
								showEmoticons={true}
								value={resolveStatusData?.statusComment || ''}
								handleEditorChange={(value: string) => {
									if (resolveStatusData?.statusComment !== value) {
										setResolveStatusData({
											...resolveStatusData,
											statusComment: value,
										});
										setCommentError('');
										setIsFormEdited(true);
									}
								}}
							/>
							{commentError ? <Typography className='error-field'>{commentError || ''}</Typography> : <></>}
						</Box>
						<Box className='action-btn'>
							<OKRButton
								disabled={Boolean(loader)}
								className={'btn-link'}
								id={'btn-cancel'}
								text={t('cancelBtn')}
								handleClick={handleCancelClick}
							/>
							<OKRButton
								disabled={Boolean(loader)}
								className={'btn-primary'}
								id={'btn-publish'}
								text={t('submit')}
								handleClick={handleSubmitClick}
							/>
						</Box>
					</Box>
				) : pipDetails?.performanceImprovementPlanStatusId > Enums.THREE ? (
					<>
						{pipDetails?.resolveDetails ? (
							<Box className='resolve-read-mode'>
								<Box className='user-info'>
									<Box className='user-img'>
										{pipDetails?.resolveDetails?.imagePath ? (
											<Avatar className='avatar-default' src={pipDetails?.resolveDetails?.imagePath} />
										) : (
											<Avatar className={'avatar-default'}>
												{getUserName({
													firstName: pipDetails?.resolveDetails?.firstName || '',
													lastName: pipDetails?.resolveDetails?.lastName || '',
													fullName: pipDetails?.resolveDetails?.fullName || '',
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4' className='font-weight-normal'>
											{`${pipDetails?.resolveDetails?.firstName || ''} ${pipDetails?.resolveDetails?.lastName || ''}`}
										</Typography>

										{pipDetails?.resolvedOn ? (
											<Typography variant='h6' className='font-weight-normal'>
												{`${getGlobalTaskListDueDate(pipDetails?.resolvedOn)} ${getTime(pipDetails?.resolvedOn)}`}
											</Typography>
										) : (
											<></>
										)}
									</Box>
								</Box>
								<Box className='long-text'>
									<Box
										dangerouslySetInnerHTML={{
											__html: pipDetails?.resolveDetails?.statusComment || '',
										}}
									></Box>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</Box>
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
