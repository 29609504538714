import { Box, ClickAwayListener, Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Assessment360DegreeIcon,
	CommittedColoredIcon,
	FinalAssessmentIcon,
	ManagerAssessmentIcon,
	PerformanceIcon,
	SelfAssessmentIcon,
} from '../../config/svg/AssessmentSvg';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';

import { OKRButton } from '../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { BottomArrowIcon } from '../../config/svg/CommonSvgIcon';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
import { UserSnapshotDrawer } from '../Perform/Snapshot/UserSnapshotDrawer';
import { getMonthDateYearTime } from '../../config/utils';
import { UserSnapshot } from '../Common/UserSnapshot';
import { Enums } from '../../config/enums';
import { InfoprolearningDomainName } from '../../config/constant';
import { getLocalStorageItem } from '../../services/StorageService';
import { BulkTeamCsvTemplateUrl, SelfAssessmentGuideTemplateUrl } from '../../config/url';

export const UserAssessmentHeader = (props: any) => {
	const {
		ref1,
		ref2,
		handleOneOnOneClick,
		userDetails,
		performanceCycle,
		fromTeamAssessment,
		employeeId,
		selfAssessmentVisibilityData,
		setSelfAssessmentVisibilityData,
		getSelfAssessmentDetails,
		setLoader,
		handleThreeSixtyBoxClick = () => {},
		setShowTeamAssessment,
		threeSixtyBackClicked,
		setThreeSixtyBackClicked,
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState<Boolean>(false);
	const [openFinalRating, setOpenFinalRating] = React.useState<Boolean>(false);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>(userDetails);
	const [activeTile, setActiveTile] = useState(1);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');

	useEffect(() => {
		if (threeSixtyBackClicked) {
			showSelfRating();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threeSixtyBackClicked]);

	const handleClick = (e: any) => {
		if (
			selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalRatingScoreVisible &&
			selfAssessmentVisibilityData?.finalRatingAssessment?.isPerformanceCycleDone
		) {
			setShowTeamAssessment(5);
			setActiveTile(5);
		}
	};
	const handleClickAway = () => {
		setOpen(false);
		setOpenFinalRating(false);
	};
	const handleRatingDropdown = () => {
		setOpenFinalRating((prev: Boolean) => !prev);
	};
	const handleRatingClickAway = () => {
		setOpenFinalRating(false);
	};
	const handleOneOnOneRedirection = (e: any) => {
		let clickType = 'MyAssessMentTab';
		let empId = 0;
		if (fromTeamAssessment) {
			empId = employeeId || 0;
			clickType = 'MyAssessmentList';
		}
		handleOneOnOneClick(e, clickType, empId);
	};
	const handleSnapshot = (e: any) => {
		if (fromTeamAssessment) {
			setSelectedUser(userDetails);
		}
		setOpenSnapshot(true);
	};
	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
	};
	const showSelfRating = () => {
		setActiveTile(1);
		setLoader(true);
		setShowTeamAssessment(1);
		getSelfAssessmentDetails(
			userDetails?.employeeId || Enums.ZERO,
			performanceCycle?.performanceCycleId || Enums.ZERO,
			Enums.ONE
		);
		setThreeSixtyBackClicked(false);
	};
	const showManagerRating = () => {
		setActiveTile(2);
		setLoader(true);
		setShowTeamAssessment(2);
		getSelfAssessmentDetails(
			userDetails?.employeeId || Enums.ZERO,
			performanceCycle?.performanceCycleId || Enums.ZERO,
			Enums.TWO
		);
	};
	const show360Rating = () => {
		setActiveTile(3);
		// setLoader(true);
		handleThreeSixtyBoxClick();
		// getSelfAssessmentDetails(
		// 	userDetails?.employeeId || Enums.ZERO,
		// 	performanceCycle?.performanceCycleId || Enums.ZERO,
		// 	Enums.THREE
		// );
	};
	return (
		<>
			<Box className='user-assessment-box-area'>
				{selfAssessmentVisibilityData && (
					<>
						<Box className='user-assessment-box-area-inner'>
							<Box className='user-assessment-box-area-left'>
								{selfAssessmentVisibilityData?.selfAssessment?.isSelfTileDisplay && (
									<Box
										className={`assessment-box ${
											selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentPeriodOpen &&
											(selfAssessmentVisibilityData?.selfAssessment?.isSelfScoreVisble ||
												selfAssessmentVisibilityData?.selfAssessment?.isSelfFormVisble)
												? 'enable-box'
												: ''
										} ${
											activeTile === 1 && selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentPeriodOpen
												? 'active'
												: ''
										}`}
										onClick={() => {
											showSelfRating();
										}}
									>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<SelfAssessmentIcon />
												<Box className='box-head-right'>
													{selfAssessmentVisibilityData?.selfAssessment?.isSelfScoreVisble ? (
														<Typography variant='h2'>
															{selfAssessmentVisibilityData?.selfAssessment?.selfAssessmentScore}
														</Typography>
													) : !selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentDone ? (
														<OKRButton
															disabled={!selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentPeriodOpen}
															className={`btn-link take-assessment`}
															text={
																selfAssessmentVisibilityData?.selfAssessment?.selfAssessmentStatus === 1
																	? t('resumeAssessment')
																	: t('takeAssessment')
															}
															icon={''}
														/>
													) : selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentDone &&
													  !selfAssessmentVisibilityData?.selfAssessment?.isSelfScoreVisble ? (
														<Typography variant='h2'>Done</Typography>
													) : (
														<></>
													)}
												</Box>
											</Box>
										</Box>
										<Box className='box-setting-des'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('performsubText', {
													performsubText: selfAssessmentVisibilityData?.selfAssessment?.selfRatingPercentage,
												})}
											</Typography>
										</Box>
										<Box
											className={`box-content ${
												selfAssessmentVisibilityData?.selfAssessment?.isAlert && 'alert-box-content'
											}`}
										>
											{' '}
											<Typography variant='h5'>
												<Tooltip title={'Today is the last day to complete assessment'} arrow>
													<Box> {selfAssessmentVisibilityData?.selfAssessment?.isAlert && <AlertInfoIcon />}</Box>
												</Tooltip>
												{t('selfAssessment')}
											</Typography>
											<Typography variant='h6'>
												{getMonthDateYearTime(selfAssessmentVisibilityData?.selfAssessment?.selfRatingStartDate)} to{' '}
												{getMonthDateYearTime(selfAssessmentVisibilityData?.selfAssessment?.selfRatingEndDate)}
											</Typography>
										</Box>
									</Box>
								)}

								{
									<Box
										className={`assessment-box  ${activeTile === 2 && 'active'} ${
											selfAssessmentVisibilityData?.managerAssessment?.isManagerAssessmentDone &&
											(selfAssessmentVisibilityData?.managerAssessment?.isManagerScoreVisible ||
												selfAssessmentVisibilityData?.managerAssessment?.isManagerFormVisible)
												? 'enable-box'
												: ''
										}`}
										onClick={() => {
											if (
												selfAssessmentVisibilityData?.managerAssessment?.isManagerAssessmentDone &&
												(selfAssessmentVisibilityData?.managerAssessment?.isManagerScoreVisible ||
													selfAssessmentVisibilityData?.managerAssessment?.isManagerFormVisible)
											) {
												showManagerRating();
											}
										}}
									>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<ManagerAssessmentIcon />
												<Box className='box-head-right'>
													{selfAssessmentVisibilityData?.managerAssessment?.isManagerScoreVisible &&
													selfAssessmentVisibilityData?.managerAssessment?.isManagerAssessmentDone ? (
														<Typography variant='h2'>
															{selfAssessmentVisibilityData?.managerAssessment?.managerAssessmentScore}
														</Typography>
													) : !selfAssessmentVisibilityData?.managerAssessment?.isManagerScoreVisible &&
													  selfAssessmentVisibilityData?.managerAssessment?.isManagerAssessmentDone &&
													  selfAssessmentVisibilityData?.managerAssessment?.isManagerFormVisible ? (
														<Typography variant='h2' className='hyperlinked'>
															Done
														</Typography>
													) : (
														<Typography variant='h2' className='blank-text'>
															--
														</Typography>
													)}
												</Box>
											</Box>
										</Box>
										<Box className='box-setting-des'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('performsubText', {
													performsubText: selfAssessmentVisibilityData?.managerAssessment?.managerRatingPercentage,
												})}
											</Typography>
										</Box>
										<Box
											className={`box-content ${
												selfAssessmentVisibilityData?.managerAssessment?.isAlert && 'alert-box-content'
											}`}
										>
											<Typography variant='h5'>
												<Tooltip title={'Today is the last day to complete assessment'} arrow>
													<Box>{selfAssessmentVisibilityData?.managerAssessment?.isAlert && <AlertInfoIcon />}</Box>
												</Tooltip>
												{t('managerAssessment')}
											</Typography>
											<Typography variant='h6'>
												{getMonthDateYearTime(selfAssessmentVisibilityData?.managerAssessment?.managerRatingStartDate)}{' '}
												to {getMonthDateYearTime(selfAssessmentVisibilityData?.managerAssessment?.managerRatingEndDate)}
											</Typography>
										</Box>
									</Box>
								}

								{selfAssessmentVisibilityData?.threeSixtyAssessment?.isThreeSixtyTileDisplay && (
									<Box
										className={'assessment-box'}
										/* onClick={() => {
										if (selfAssessmentVisibilityData?.threeSixtyAssessment?.isThreeSixtyScoreVisible) {
											show360Rating();
										}
									}} */
									>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<Assessment360DegreeIcon />
												<Box className='box-head-right'>
													{selfAssessmentVisibilityData?.threeSixtyAssessment?.isThreeSixtyScoreVisible ? (
														<Typography variant='h2'>
															{selfAssessmentVisibilityData?.threeSixtyAssessment?.threeSixtyAssessmentScore}
														</Typography>
													) : (
														!selfAssessmentVisibilityData?.threeSixtyAssessment?.isThreeSixtyScoreVisible &&
														(selfAssessmentVisibilityData?.threeSixtyAssessment?.threeSixtyAssessmentScore > 0 ? (
															<Typography variant='h2'>Done</Typography>
														) : (
															<Typography variant='h2' className='blank-text'>
																--
															</Typography>
														))
													)}
													<OKRButton
														className={`btn-link`}
														text={`${
															selfAssessmentVisibilityData?.threeSixtyAssessment?.nomineeApproveCount +
															'/' +
															selfAssessmentVisibilityData?.threeSixtyAssessment?.nomineeTotalCount
														} Assessed`}
														icon={''}
														disabled={true}
													/>
												</Box>
											</Box>
										</Box>
										<Box className='box-setting-des'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('performsubText', {
													performsubText: selfAssessmentVisibilityData?.threeSixtyAssessment?.threeSixtyPercentage,
												})}
											</Typography>
										</Box>
										<Box
											className={`box-content ${
												selfAssessmentVisibilityData?.threeSixtyAssessment?.isAlert && 'alert-box-content'
											}`}
										>
											<Typography variant='h5'>
												<Tooltip title={'Today is the last day to complete assessment'} arrow>
													<Box>{selfAssessmentVisibilityData?.threeSixtyAssessment?.isAlert && <AlertInfoIcon />}</Box>
												</Tooltip>
												<span dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}></span>
											</Typography>
											<Typography variant='h6'>
												{getMonthDateYearTime(selfAssessmentVisibilityData?.threeSixtyAssessment?.threeSixtyStartDate)}{' '}
												to {getMonthDateYearTime(selfAssessmentVisibilityData?.threeSixtyAssessment?.threeSixtyEndDate)}
											</Typography>
										</Box>
									</Box>
								)}
								{selfAssessmentVisibilityData?.committedOkrAssessment?.isOkrTileDisplay && (
									<Box className='assessment-box  '>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<CommittedColoredIcon />
												<Box className='box-head-right'>
													<Typography variant='h2'>
														{selfAssessmentVisibilityData?.committedOkrAssessment?.commitedOKrScore}
													</Typography>
												</Box>
											</Box>
										</Box>
										<Box className='box-setting-des'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('performsubText', {
													performsubText: selfAssessmentVisibilityData?.committedOkrAssessment?.okrProgressPercentage,
												})}
											</Typography>
										</Box>
										<Box className='box-content'>
											<Typography variant='h5'>{t('committedOKRs')}</Typography>
											<Typography variant='h6'></Typography>
										</Box>
									</Box>
								)}
								<Box
									className={`assessment-box assessment-box-type-2 ${open ? 'open-final-rating' : ''} ${
										activeTile === 5 && 'active'
									} ${
										selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalRatingScoreVisible &&
										selfAssessmentVisibilityData?.finalRatingAssessment?.isPerformanceCycleDone &&
										'enable-box'
									}`}
									onClick={(e: any) => handleClick(e)}
								>
									<Box className='default-final-rating'>
										<Box className='box-outer-head'>
											<Box className='box-head'>
												<FinalAssessmentIcon />
												<Box className='box-head-right'>
													{selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalRatingScoreVisible ? (
														<Typography variant='h2'>
															{selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalScoreVisible ? (
																selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingScore
															) : (
																<Typography variant='h2' className='hyperlinked'>
																	Done
																</Typography>
															)}
														</Typography>
													) : (
														<Typography variant='h2' className='blank-text'>
															--
														</Typography>
													)}
													<Typography variant='h6' className='overall-txt'>
														{selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalRatingScoreVisible &&
														selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingId &&
														selfAssessmentVisibilityData?.finalRatingAssessment?.isFinalScoreVisible
															? selfAssessmentVisibilityData?.finalRatingAssessment.finalRatingLabel
															: ''}
													</Typography>
												</Box>
											</Box>
										</Box>
										<Box className='box-setting-des'></Box>

										<Box
											className={`box-content ${
												selfAssessmentVisibilityData?.finalRatingAssessment?.isAlert && 'alert-box-content'
											}`}
										>
											<Typography variant='h5'>
												<Tooltip title={'Today is the last day to complete assessment'} arrow>
													<Box>{selfAssessmentVisibilityData?.finalRatingAssessment?.isAlert && <AlertInfoIcon />}</Box>
												</Tooltip>
												{t('finalAssessment')}
											</Typography>
											<Typography variant='h6'>
												{getMonthDateYearTime(
													selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingStartDate
												)}{' '}
												to{' '}
												{getMonthDateYearTime(selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingEndDate)}
											</Typography>
										</Box>
									</Box>
									{open && (
										<Box className='expand-final-rating'>
											<Box className='box-outer-head'>
												<Box className='box-head'>
													<FinalAssessmentIcon />
													<Box className='box-head-right'>
														<Typography variant='h2' className='blank-text'>
															--
														</Typography>
														<Typography variant='h2'>
															{open && <span className='overall-txt'>{t('overallLabel')}</span>}
														</Typography>
													</Box>
												</Box>
											</Box>
											<Box className='box-content'>
												<Typography variant='h5'>{t('finalAssessment')}</Typography>
												<Typography variant='h6'>
													{getMonthDateYearTime(
														selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingStartDate
													)}{' '}
													to{' '}
													{getMonthDateYearTime(
														selfAssessmentVisibilityData?.finalRatingAssessment?.finalRatingEndDate
													)}
												</Typography>
											</Box>
										</Box>
									)}
								</Box>
							</Box>

							{/* <Box className='user-assessment-box-area-right'>
							<div className='assessment-right-box' onClick={handleSnapshot} ref={ref1}>
								<Box className='box-content'>
									<Box className='icon performance-icon'>
										<PerformanceIcon />
									</Box>
									<Typography variant='h5'>{t('performanceSnapshot')}</Typography>
								</Box>
							</div>
							{selfAssessmentVisibilityData?.isOneToOneReview && (
								<div className='assessment-right-box' onClick={handleOneOnOneRedirection} ref={ref2}>
									<Box className='box-content'>
										<Box className='icon request-1on1-icon'>
											<RequestOneOnOneIcon2 />
										</Box>
										<Typography variant='h5'>{t('requestOnetoOne')}</Typography>
									</Box>
								</div>
							)}
						</Box> */}

							{/* <Box className='score-info'>
							{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
								<>
									<Typography variant='h6' className='font-weight-normal'>
										{t('Mid-Year Development Review Assessment Period:')}
									</Typography>
									<Typography variant='h6' className='font-weight-bold'>
										{t('January 1 to June 30.')}
									</Typography>
								</>
							) : (
								<>
									<Typography variant='h6' className='font-weight-normal'>
										{t('scoreInfo1')} {selfAssessmentVisibilityData?.scoreCalculatedUpon}
									</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{t('scoreInfo2')}
									</Typography>
								</>
							)}
						</Box> */}
						</Box>
					</>
				)}
			</Box>
			{selfAssessmentVisibilityData && (
				<Box className='mid-year-review-info'>
					{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
						<>
							{/* <Typography variant='h6' className='font-weight-normal'>
								{t('Mid-Year Development Review Assessment Period: ')} <strong>{t('January 1 to June 30.')}</strong>
							</Typography> */}
						</>
					) : (
						<>
							<Typography variant='h6' className='font-weight-normal'>
								{t('scoreInfo1')} {selfAssessmentVisibilityData?.scoreCalculatedUpon}
							</Typography>
							<Typography variant='h6' className='font-weight-normal'>
								{t('scoreInfo2')}
							</Typography>
						</>
					)}
				</Box>
			)}
			{/* <UserSnapshotDrawer
				openSnapshot={openSnapshot}
				selectedUser={selectedUser}
				performanceCycle={performanceCycle}
				handleDrawerClose={handleDrawerClose}
				isTeamAssessment={props.fromTeamAssessment ? true : false}
			/> */}
			{openSnapshot ? (
				<UserSnapshot
					openSnapshot={openSnapshot}
					selectedUser={selectedUser}
					// performanceCycle={performanceCycle}
					handleDrawerClose={handleDrawerClose}
					// isTeamAssessment={props.fromTeamAssessment ? true : false}
				/>
			) : (
				<></>
			)}
		</>
	);
};
