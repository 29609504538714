import React, { useEffect, useState } from 'react';
import {
	Popper,
	Grow,
	List,
	ListItem,
	Checkbox,
	Paper,
	ClickAwayListener,
	FormControlLabel,
	Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../../Common/OKRButton';
import { NewFilterIcon } from '../../../../../config/svg/CommonSvgIcon';
import { DownArrowIcon, UpArrowIcon } from '../../../../../config/svg/ArrowSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../../../../config/svg/formElementIcons';

export const ReviewWorkflowReversalCycleFilter: React.FC<any> = (props) => {
	const { reviewWorkflowReversalCycleList, setFinalFilterCondition } = props;
	const { t } = useTranslation();
	const [localFilterCondition, setLocalFilterCondition] = useState<any[]>([]);
	const [appliedFilter, setAppliedFilter] = useState<boolean>(false);

	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	const handleFilterChanged = (value: number) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value);
		if (indexParent === -1) {
			filterData.push(value);
		} else {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
	};

	const handleApplyFilterClick = (filtersData: any) => {
		let filters: any = [];
		reviewWorkflowReversalCycleList.map((value: any, index: number) => {
			if (filtersData && filtersData.indexOf(value.performanceCycleId) !== -1) {
				filters.push({
					...value,
				});
			}
		});
		setAppliedFilter(true);
		setFinalFilterCondition([...filters]);
	};
	const handleCloseChip = (value: any) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value.performanceCycleId);
		if (indexParent !== -1) {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
		handleApplyFilterClick(filterData);
	};

	const clearFilter = () => {
		setLocalFilterCondition([]);
		setFinalFilterCondition([]);
	};
	return (
		<Box className='filter-list-dropdown'>
			{/* <Box className={`filter-chip `}>
				{appliedFilter && (
					<List>
						{finalFilterCondition && finalFilterCondition.length > 0 ? (
							<>
								{finalFilterCondition.slice(0, 1).map((item: any, index: any) => (
									<ListItem key={index}>
										<Tooltip arrow title={item.name}>
											<Typography variant='body2'>{item.name}</Typography>
										</Tooltip>
										<Button className='cancel-btn' onClick={() => handleCloseChip(item)}>
											<CancelIcon />
										</Button>
									</ListItem>
								))}
								{finalFilterCondition.length > 1 && (
									<ListItem className='more-filter-count'>
										<Typography variant='body2'>+{finalFilterCondition.length - 1}</Typography>
									</ListItem>
								)}

								<ListItem className='clear-all'>
									<Link onClick={clearFilter}>{t('clearAll')}</Link>
								</ListItem>
							</>
						) : (
							<></>
						)}
					</List>
				)}
			</Box> */}
			<OKRButton
				className={`list-filter-btn ${openFilter ? 'active' : ''}`}
				icon={<NewFilterIcon />}
				text={
					<span className='filter-txt'>
						{t('filterLabel')}
						{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
					</span>
				}
				handleClick={handleToggleFilter}
				id='dashboard-filter-btn'
			/>
			<Popper
				className={`filter-list-popup`}
				open={openFilter}
				anchorEl={anchorEl}
				id='workflow-reversal-filter'
				placement={'bottom-start'}
				modifiers={{
					flip: {
						enabled: false,
					},
				}}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<ClickAwayListener onClickAway={handleClose}>
							<Paper>
								<Box className='list-filter-wrap'>
									<Box className='list-filter-wrap-inner'>
										<List disablePadding>
											{reviewWorkflowReversalCycleList &&
												reviewWorkflowReversalCycleList.length &&
												reviewWorkflowReversalCycleList.map((item: any, index: any) => {
													return (
														<>
															<ListItem>
																<FormControlLabel
																	control={
																		<Checkbox
																			icon={<BorderCheckboxIcon />}
																			checkedIcon={<CheckedIcon />}
																			checked={
																				localFilterCondition && localFilterCondition.includes(item.performanceCycleId)
																			}
																			value={item.performanceCycleId}
																			onChange={(e) => handleFilterChanged(item.performanceCycleId)}
																			color='primary'
																		/>
																	}
																	label={item.name}
																/>
															</ListItem>
														</>
													);
												})}
										</List>
									</Box>
									<Box className='filter-list-actions'>
										<OKRButton
											className='apply-btn btn-primary'
											text={t('applyLabel')}
											handleClick={(e: any) => {
												handleClose(e);
												handleApplyFilterClick(localFilterCondition);
											}}
										/>
									</Box>
								</Box>
							</Paper>
						</ClickAwayListener>
					</Grow>
				)}
			</Popper>
		</Box>
	);
};
