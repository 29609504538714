import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Link,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CkEditor from '../Common/CkEditor/CkEditor';
import {
	AddIconSvg,
	AddTrainingsIcon,
	CalendarIcon,
	CrossIcon,
	LoadMoreIcon,
	NameRenameIcon,
	SearchIcon,
	TickIcon,
	TimeIcon,
	TrainingIcon,
} from '../../config/svg/CommonSvgIcon';
import DummyImage from '../../images/dummy-training-image.jpg';
import { BackArrow, GreyLogo } from '../../config/svg/GlobalSvg';
import { Skeleton } from '@material-ui/lab';
import { OKRButton } from '../Common/OKRButton';
import { getFullName, getUserName } from '../../config/utils';
import { MultiSelect } from '../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';
import { courseSearch, getAllCourseList, getCompunnelTrainingCategoryApi } from '../../action/courses';
import { useDispatch } from 'react-redux';
import { CoursesToken } from '../../config/url';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import { DirectReportReviewIcon, FinalAssessmentIcon } from '../../config/svg/CommonSvg';
import { NoRecord } from '../Admin/CommonComponent/NoRecord';

export const TeamReviewTrainingsSearch: React.FC<any> = (props) => {
	const { handleAddMoreTrainingCancel, drUserData, users, index, selectCourses, trainingDataFinal, setAddMoreClicked } =
		props;
	const [searchBoxValue, setSearchBoxValue] = useState<string>('');
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [coursesDataFinal, setCoursesDataFinal] = useState<any>([]);
	const [courseDataSelected, setCourseDataSelected] = useState<any>({});
	const [showBulkOption, setShowBulkOption] = useState(false);
	const [checkedUser, setCheckedUser] = useState(0);
	const [selectedUser, setSelectedUser] = useState<any>([]);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [noRecordsSearch, setNoRecordsSearch] = useState<any>(null);

	const pageSize = 16;
	const defaultPagingInfo: any = {
		pageIndex: 0,
		pageSize: pageSize,
		totalPages: 1,
		totalRecords: 0,
	};
	const [pagingInfo, setPagingInfo] = useState<any>({ ...defaultPagingInfo });
	const [emptyRecords, setEmptyRecords] = useState<any>({
		categoryDropdown: false,
	});

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const formDisabled = false;
	const htmlContent = '<b>Approved</b> with <i>minor feedback</i>';
	useEffect(() => {
		getCategoryList();
		//getAllList();
	}, []);
	useEffect(() => {
		if (pagingInfo && pagingInfo.pageIndex !== 0) {
			getAllList();
		}
	}, [pagingInfo]);
	const handleGoalCycleValueChange = (e: any) => {
		setSearchBoxValue(e.target.value);
	};
	const onUserSelect = (e: any, courseData: any) => {
		let { checked } = e.target;
		let courseDataSelectedCopy: any = { ...courseDataSelected };
		if (checked) {
			courseDataSelectedCopy[courseData.id] = courseData;
		} else {
			delete courseDataSelectedCopy[courseData.id];
		}
		if (Object.keys(courseDataSelectedCopy).length > 0) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
		setCourseDataSelected(courseDataSelectedCopy);
	};
	const handleMultipleReconsider = (e: any) => {
		selectCourses(courseDataSelected);
	};
	const clearTraining = (e: any) => {
		setSearchBoxValue('');
		setSelectedUser([]);
		setNoRecordsSearch(false);
	};
	const getCategoryList = async () => {
		const courseListing: any = await dispatch(getCompunnelTrainingCategoryApi());
		setReportingToOptions(
			courseListing?.data.entity.map((data: any) => {
				return {
					...data,
					label: data.name,
					value: data.id,
				};
			})
		);
		return { data: courseListing };
	};
	const getAllList = async () => {
		const payload: any = {
			performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
			pageIndex: pagingInfo.pageIndex ? pagingInfo.pageIndex : 1,
			pageSize: pagingInfo.pageSize ? pagingInfo.pageSize : pageSize,
			sortingText: '',
			order: '',
			finder: searchBoxValue,
			category_Ids: selectedUser.length > 0 ? [selectedUser[0].id] : [],
			course_Ids: [],
			competency: '',
		};
		/* const courseListings: any = {
			entity: {
				pageIndex: 1,
				pageSize: 10,
				totalRecords: 46,
				totalPages: 5,
				headerCode: 0,
				records: [
					{
						id: 14,
						fullName: 'Mindful Listening',
						shortName: 'Mindful Listening',
						description:
							'<p>Effective listeners both in the office and virtually are essential to thriving in the modern digitalized workforce. This module will help you discover the benefits of being a mindful listener, understand the impacts of ineffective listening, and overcome barriers limiting your potential.</p>',
						thumbnail: 'https://lms-staging.compunnel.com/pluginfile.php/87/course/overviewfiles/0/apex.png',
						category: 'Soft Skills',
						startDate: '2023-12-08',
						endDate: '2024-12-07',
						isSelected: false,
					},
					{
						id: 15,
						fullName: 'Excel 365: Advanced',
						shortName: 'Excel 365: Advanced',
						description:
							"<p>This course will teach students advanced concepts and formulas in Microsoft Excel 365. Students will learn functions such as SUMIF, AVERAGEIF, and COUNTIF, advanced lookup functions, and complex logical and text functions. Additionally, students will experiment with auditing formulas and error checking, use the What-If Analysis tools, learn the options for worksheet and workbook protection, work with Macros, use form controls, and ensure data integrity in their worksheets and workbooks. Students will also learn about Excel's many collaboration features and import and export data to and from their workbooks. This course has an assessment which may be required for a certificate to be generated.<br>This course aligns with the CAP Body of Knowledge and should be approved for 4.25 recertification points under the Technology and Information Distribution content area. Email info@intellezy.com with proof of completion of the course to obtain your certificate.</p>",
						thumbnail: 'https://lms-staging.compunnel.com/pluginfile.php/90/course/overviewfiles/0/edutech.png',
						category: 'IER',
						startDate: '2023-12-08',
						endDate: '2024-12-07',
						isSelected: false,
					},
					{
						id: 16,
						fullName: 'Excel 365: Beginner',
						shortName: 'Excel 365: Beginner',
						description:
							'<p>Microsoft Excel is one of the major workhorses in the Microsoft Office suite of applications and arguably the most widely-used Office product. This course gives an introduction to Excel via the Office 365 Suite. A solid understanding of how to use this program is vital to nearly everyone in most industries today. This course is designed specifically to teach you the fundamentals of Excel and give you the skills you need to begin to create and manipulate worksheets. Specifically, you will understand how to create, save, enter data, and print spreadsheets in addition to learning how to create formulas and use functions to calculate in Excel. You will learn how to enter data, format spreadsheets, manipulate columns and rows, add headers and footers, and use page setup options to prepare them for printing. You will also learn how to handle large worksheets or multiple workbooks, use styles and themes, insert various graphic objects, and options for proofing your workbooks. This course has an assessment which may be required for a certificate to be generated.<br>This course aligns with the CAP Body of Knowledge and should be approved for 4.75 recertification points under the Technology and Information Distribution content area. Email info@intellezy.com with proof of completion of the course to obtain your certificate.</p>',
						thumbnail:
							'https://lms-staging.compunnel.com/pluginfile.php/92/course/overviewfiles/0/images_ms-365-excel-beginner%20%281%29.jpg',
						category: 'Soft Skills',
						startDate: '2023-12-08',
						endDate: '2024-12-07',
						isSelected: false,
					},
					{
						id: 17,
						fullName: '13 Common Recruiting Mistakes',
						shortName: '13 Common Recruiting Mistakes',
						description:
							'<p>The goal of this course is to talk about 13 common recruiting mistakes and give you ways to avoid these. The objective of this training is to help you take corrective actions that impact clients and candidates. The 13 common recruiting mistakes discuss in this course are -&nbsp; not understanding client requirements, not understanding candidate&rsquo;s priorities and interests, not handling counter offers effectively, not following up with the candidates, overselling the job, rushing candidate&rsquo;s through technology, not establishing a time frame for hiring, pressurizing candidates, relying on buzz words and sales speak, always trusting the resume, poor communication, waiting for the perfect candidate and not screening candidates on social media.&nbsp;</p>',
						thumbnail:
							'https://lms-staging.compunnel.com/pluginfile.php/97/course/overviewfiles/0/RecruitingMistakeS2.png',
						category: 'PHP',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 18,
						fullName: 'ACCELEARTE',
						shortName: 'ACCELEARTE',
						description: '',
						thumbnail:
							'https://lms-staging.compunnel.com/pluginfile.php/101/course/overviewfiles/0/Interpersonal%20Skills.jpg',
						category: 'Business',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 19,
						fullName: 'Account Management Training',
						shortName: 'Account Management Training',
						description:
							'<p>This tailor-made account manager training course are for both aspiring and inspiring account managers . If you are aspiring to be an account manager this course will give you a structure, key elements, technical and functional aspects, important key stake- holders and ways to know and work with them. If you are one of the inspiring account manager who has discovered the key to success &ndash; this course will give you professional approach to circle your important job duties that you may have been missing and this course also tells you the challenges and ways to work around them.</p>',
						thumbnail:
							'https://lms-staging.compunnel.com/pluginfile.php/103/course/overviewfiles/0/account_manager_509x350px.jpg',
						category: 'PHP',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 20,
						fullName: 'Call Opening and Preparedness',
						shortName: 'Call Opening and Preparedness',
						description: '',
						thumbnail: null,
						category: 'Catalog',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 21,
						fullName: 'Candidate Referral Program',
						shortName: 'Candidate Referral Program',
						description:
							'<p>This course talks about what referral program is, what&rsquo;s in it for you, what steps you need to take to refer someone and how you can track the status of your referee, Also how much referral bonus you get and how to claim it. And in the last, who can resolve your referral related queries.</p>',
						thumbnail: 'https://lms-staging.compunnel.com/pluginfile.php/109/course/overviewfiles/0/Refer.png',
						category: 'Group of Course Category',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 22,
						fullName: 'CoC 2022',
						shortName: 'CoC 2022',
						description: '',
						thumbnail: null,
						category: 'Group of Course Category',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
					{
						id: 23,
						fullName: 'Compliance Training: ISMS',
						shortName: 'Compliance Training: ISMS',
						description: '',
						thumbnail: null,
						category: 'Catalog',
						startDate: '2023-12-09',
						endDate: '2024-12-08',
						isSelected: false,
					},
				],
				results: null,
			},
			entityList: null,
			messageList: {},
			messageType: 'Success',
			isSuccess: true,
			status: 200,
			paggingInfo: null,
		}; */
		const courseListings: any = await dispatch(getAllCourseList(payload));
		let selectedData: any[] = trainingDataFinal.map((item: any) => item.id);
		if (courseListings.data.entity) {
			let trainingCourses = courseListings.data.entity.records.filter((item: any) => !selectedData.includes(item.id));
			if (courseListings.data.entity.totalPages > 1 && courseListings.data.entity.totalPages > pagingInfo.pageIndex) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}
			let courseData: any[] = [...trainingCourses];
			if (pagingInfo.pageIndex !== 1) {
				courseData = [...coursesDataFinal, ...courseData];
			}
			setCoursesDataFinal(courseData);
			setNoRecordsSearch(courseData.length === 0 ? true : false);
		} else {
			setNoRecordsSearch(true);
		}
	};
	return (
		<>
			<Box className='add-new-program-area full-rectangular-card'>
				{/* <Box className='program-cancel-btn'>
					<OKRButton
						icon={<CrossIcon />}
						title={t('backToFinalRatingRecommendations')}
						className={'btn-cross'}
						id={'btn-close'}
						handleClick={(e) => {
							setAddMoreClicked(false);
						}}
					/>
				</Box> */}
				<Box className='add-new-program-row'>
					<Box className='add-new-program-col'>
						<Box className='final-rating-label'>
							<NameRenameIcon />
							<Typography>{t('programLabel')}</Typography>
						</Box>
						<TextField
							id='name'
							fullWidth
							className='helperText-bottom'
							placeholder={t('searchText')}
							value={searchBoxValue || ''}
							name='name'
							onChange={handleGoalCycleValueChange}
							inputProps={{ maxLength: 50 }}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box className='add-new-program-col'>
						<Box className='final-rating-label'>
							<NameRenameIcon />
							<Typography>{t('categoryLevel')}</Typography>
						</Box>
						<Box className={`select-role-fields ${selectedUser && selectedUser?.length ? 'selected' : ''}`}>
							<MultiSelect
								key={'categoryDropdown'}
								id='category-dropdown'
								selectedOptions={selectedUser}
								optionsList={reportingToOptions}
								onSelectOption={(value: any) => {
									setSelectedUser([...value]);
									setEmptyRecords({ ...emptyRecords, reportingTo: false });
								}}
								blurCalled={(value: any) => {
									/* setReportingToOptions([]); */
								}}
								setEmptyRecords={setEmptyRecords}
								blurInputOnSelect={true}
								isMulti={true}
								customFilter={() => {}}
								setOptionsList={setReportingToOptions}
								isLoadMore={false}
								placeHolder={t('selectLabel')}
								noOptionsMessage={<NoSearchRecord />}
								showNoOptions={emptyRecords.reportingTo === true}
								fetchAsyncData={true}
								isSingleSelection={true}
								closeMenuOnSelect={true}
								labelTemplate={'avatarLabel'}
								selectClassName={`select-search-dropdown select-search-icon`}
								selectClassNamePrefix={'react-select'}
								error={''}
								onInputFocus={() => {}}
								performSearch={getCategoryList}
								hideLoggedIn={true}
								loggedInUserIds={[]}
								fieldLabel={'name'}
								fieldId={'id'}
								performSearchOnFocus={true}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='performance-goals-action'>
					<Box>
						<OKRButton
							text={t('backToFinalRatingRecommendations')}
							className={'btn-link'}
							id={'btn-close'}
							handleClick={(e) => {
								setAddMoreClicked(false);
							}}
						/>
					</Box>
					<Box>
						<OKRButton
							className={'btn-link'}
							id='program_clear_form'
							text={t('clear')}
							disabled={searchBoxValue == '' && selectedUser && selectedUser.length === 0}
							handleClick={clearTraining}
						/>
						<OKRButton
							className={'btn-primary'}
							id='program_search_form'
							text={t('searchText')}
							handleClick={() => {
								setPagingInfo({ ...defaultPagingInfo, pageIndex: 1 });
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box className='personalized-growth-card full-rectangular-card'>
				<Box className='personalized-growth-head'>
					<Box className='personalized-growth-head-left'>
						{(coursesDataFinal && coursesDataFinal?.length) || noRecordsSearch === true ? (
							<Typography variant='h4' className='font-weight-normal'>
								{t('searchResults')}
							</Typography>
						) : (
							<>
								<Typography variant='h4' className='font-weight-normal'>
									{t('personalizedGrowthPathways')}
								</Typography>
								<Typography variant='subtitle2' className='subtitle3'>
									{t('personalizedGrowthPathwaysText')}
								</Typography>
							</>
						)}
					</Box>
				</Box>

				{noRecordsSearch !== true ? (
					<Box className='course-card-panel-list'>
						{coursesDataFinal && coursesDataFinal.length > 0 ? (
							coursesDataFinal.map((item: any, index: number) => (
								<Box className='course-card-panel'>
									<Box className='courses-img'>
										<img src={item.thumbnail} alt='' />

										<Box className='training-checkbox'>
											<FormControlLabel
												control={
													<Checkbox
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														name={'list' + index}
														checked={courseDataSelected[item.id] ? true : false}
														onChange={(e: any) => onUserSelect(e, item)}
														key={index}
														/* disabled={
													users.isPerformanceCycleDone ||
													(users?.isManagerAssessmentCompleted &&
														users?.managerAssessmentScore &&
														users.finalRatingId > 0)
												} */
													/>
												}
												label=''
											/>
										</Box>
									</Box>
									<Box className='courses-content-section'>
										<Box className='course-content-header'>
											<Typography variant='subtitle2'>{item.category}</Typography>
											<Box className='course-title'>
												<Tooltip classes={{ popper: 'tooltip-layout-trainings' }} title={item.shortName} arrow>
													<Typography variant='h4' className='font-weight-normal'>
														{item.shortName}
													</Typography>
												</Tooltip>
											</Box>
										</Box>
										<Box className='course-session'>
											<Box className='course-published'>
												<CalendarIcon />
												<Typography variant='h6'>{item.startDate}</Typography>
											</Box>
										</Box>
										<Box className='course-description'>
											<Tooltip
												classes={{ popper: 'tooltip-layout-trainings' }}
												title={item?.description && <span dangerouslySetInnerHTML={{ __html: item?.description }} />}
												arrow
											>
												<Typography
													variant='body2'
													className='font-weight-normal'
													dangerouslySetInnerHTML={{ __html: item.description }}
												></Typography>
											</Tooltip>
										</Box>
									</Box>
								</Box>
							))
						) : (
							<>
								{/* No Course section */}
								<Box className='course-card-panel'>
									<Box className='courses-img no-course-img'>
										<Box className='no-img-content'>
											{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
										</Box>
									</Box>
									<Box className='courses-content-section'>
										<Box className='course-content-header'>
											<Skeleton animation={false} width='70px'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
										<Box className='course-session'>
											<Box className='course-published'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
											<Box className='courses-duration'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
										</Box>
										<Box className='course-description'>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</Box>
								<Box className='course-card-panel'>
									<Box className='courses-img no-course-img'>
										<Box className='no-img-content'>
											{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
										</Box>
									</Box>
									<Box className='courses-content-section'>
										<Box className='course-content-header'>
											<Skeleton animation={false} width='70px'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
										<Box className='course-session'>
											<Box className='course-published'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
											<Box className='courses-duration'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
										</Box>
										<Box className='course-description'>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</Box>
								<Box className='course-card-panel'>
									<Box className='courses-img no-course-img'>
										<Box className='no-img-content'>
											{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
										</Box>
									</Box>
									<Box className='courses-content-section'>
										<Box className='course-content-header'>
											<Skeleton animation={false} width='70px'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
										<Box className='course-session'>
											<Box className='course-published'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
											<Box className='courses-duration'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
										</Box>
										<Box className='course-description'>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</Box>
								<Box className='course-card-panel'>
									<Box className='courses-img no-course-img'>
										<Box className='no-img-content'>
											{/* <GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography> */}
										</Box>
									</Box>
									<Box className='courses-content-section'>
										<Box className='course-content-header'>
											<Skeleton animation={false} width='70px'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
										<Box className='course-session'>
											<Box className='course-published'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
											<Box className='courses-duration'>
												<Skeleton animation={false} width='70px'>
													<Typography>.</Typography>
												</Skeleton>
											</Box>
										</Box>
										<Box className='course-description'>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
											<Skeleton animation={false} width='100%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</Box>{' '}
							</>
						)}
					</Box>
				) : (
					noRecordsSearch === true && <NoRecord noRecordMessage={t('noPerformanceFound')} subHead={' '} {...props} />
				)}
			</Box>
			{showLoadMore && (
				<Box className='load-more-box'>
					<OKRButton
						className='load-more-btn'
						icon={<LoadMoreIcon />}
						text={t('loadMore')}
						handleClick={(e) => {
							setPagingInfo({ ...pagingInfo, pageIndex: pagingInfo.pageIndex + 1 });
						}}
					/>
				</Box>
			)}
			{showBulkOption && (
				<Box className='bulk-user-edit-bar bulk-user-edit-bar-type2'>
					<Box className='bulk-user-selected'>
						<Typography variant='body1'>
							<TickIcon />
							{Object.keys(courseDataSelected).length} {t('selectedLabel')}
						</Typography>
					</Box>
					<Box className='bulk-user-action'>
						<List component='ul' disablePadding>
							<ListItem>
								<OKRButton
									icon={<AddTrainingsIcon />}
									title={t('addTrainings')}
									handleClick={(e) => handleMultipleReconsider(e)}
									className='action-btn'
								></OKRButton>
							</ListItem>
						</List>
					</Box>
				</Box>
			)}
		</>
	);
};
