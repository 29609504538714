import React, { useEffect, useRef, useState } from 'react';
import {
	Avatar,
	Badge,
	Box,
	Chip,
	Collapse,
	Link,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { AttachmentIcon, CalendarIcon, KpiIcon, RightArrowIcon } from '../../config/svg/CommonSvgIcon';
import '../../styles/pages/user-goals/user-goals-listing.scss';
import '../../styles/pages/user-goals/teams-goals-card.scss';
import { AlertInfoIcon, ConversationIcon, ResponseLogIcon } from '../../config/svg/CommonSvg';
import CkEditor from '../Common/CkEditor/CkEditor';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { CategorySettings } from './CategorySettings';
import {
	getGoalAlertConversation,
	getPerformanceGoalDocument,
	getPerformanceGoalList,
	saveUserGoalApi,
	setPerfomanceGoalAttachmentCount,
} from '../../action/adminSettings';
import {
	checkUnSavePopUpVisible,
	formatDate,
	formatFeedbackDate,
	getMonthDateYearTime,
	getUserDetails,
	getUserName,
} from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../config/enums';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import WelcomeImage from '../../images/my-goal-bg.svg';
import AlertDialog from '../Common/Dialog';
import { setPerformanceGoalForRedirection } from '../../action/common';
import { useSnackbar } from 'notistack';
import { CommonEditorBox } from '../Common/CommonEditorBox';
import { GoalSettings } from './GoalSettings';
import { NumberFormatComponent } from '../Common/NumberFormat';

export const PerformanceGoals: React.FC<any> = (props) => {
	const { setImageList, imageList, uploadCase, activeStep, cycleData, drUserData, fromTeamDetails } = props;
	const [editorText, setEditorText] = useState<any>('');
	const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const { t } = useTranslation();
	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [currentTab, setCurrentTab] = useState<string>('');
	const dispatch = useDispatch();
	const [performanceGoalsList, setPerformanceGoalsList] = useState<any>({});
	const [expandedGoalList, setExpandedGoalList] = useState<number[]>([]);
	const [errorText, setErrorText] = useState<any>('');
	const { performanceGoalCycle } = useSelector((state: any) => state?.adminSettingReducer);
	const [descripError, setDescripError] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, isDraft: false, status: 0 });
	const { redirectedPerformanceGoalDetails } = useSelector((state: any) => state.commonReducer);
	const { enqueueSnackbar } = useSnackbar();
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [goalSettingsOpen, setGoalSettingsOpen] = useState<any>({ open: false });

	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 20);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 20);
	useEffect(() => {
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
		setPerformanceGoalsList({});
		setIsDataChanged(false);
	}, []);
	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDataChanged]);
	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = document.getElementById('createLinkPopupDetail');
		const id2 = document.getElementById('back-to-main');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			checkUnSavePopUpVisible(event) &&
			isDataChanged &&
			((!ref.current?.contains(event.target) &&
				!id?.contains(event.target) &&
				!(class1.length > 0 && class1[0].contains(event.target))) ||
				id2?.contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsDataChanged(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	useEffect(() => {
		//notification redirection for conversation
		if (redirectedPerformanceGoalDetails && performanceGoalsList) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (categoryId) {
				const performanceGoalsCategory = performanceGoalsList?.performanceGoalSetListingCategories?.find(
					(item: any) => item.performanceGoalLinkedFormCategoryId === categoryId
				);
				if (performanceGoalsCategory) {
					handleSettingsDrawerOpen(null, performanceGoalsCategory, 'conversation');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails, performanceGoalsList]);

	useEffect(() => {
		if (performanceGoalCycle !== null) {
			getUserPerformanceGoalsList();
		}
	}, [performanceGoalCycle]);

	const handleExpandGoals = (e: any, goals: any) => {
		let list = Object.assign([], expandedGoalList);
		const elementIndex = list.indexOf(goals.performanceGoalLinkedFormCategoryId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(goals.performanceGoalLinkedFormCategoryId);
		}
		setExpandedGoalList(list);
	};
	const handleSettingsDrawerOpen = (event: any, performanceGoalSetListingFormDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: performanceGoalSetListingFormDetails });
		dispatch(setPerfomanceGoalAttachmentCount(0));
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};
	const userDetail = getUserDetails();

	const handleSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setCategorySettingsOpen({ open: false, data: '' });
		if (currentTab === 'conversation') {
			//getUserPerformanceGoalsList();
			dispatch(getGoalAlertConversation());
		}
	};

	const handleGoalSettingsDrawerOpen = (
		event: any,
		performanceGoalSetListingFormDetails: any,
		tabToOpen?: string,
		goalList?: any,
		goalListIndex?: any
	) => {
		setGoalSettingsOpen({
			open: true,
			data: { ...performanceGoalSetListingFormDetails, goalList: goalList, goalListIndex: goalListIndex },
		});
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};
	const handleGoalSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		let performanceGoalsListCpy = { ...performanceGoalsList };
		let catIndex = goalSettingsOpen?.data?.goalListIndex?.catIndex;
		let goalIndex = goalSettingsOpen?.data?.goalListIndex?.goalIndex;
		if (catIndex !== undefined && goalIndex !== undefined) {
			performanceGoalsListCpy.performanceGoalSetListingCategories[catIndex].performanceGoalSetListingFormDetails[
				goalIndex
			]['isResponseLogRedDotAlert'] = false;
		}
		setGoalSettingsOpen({ open: false, data: '' });
		setPerformanceGoalsList(performanceGoalsListCpy);
	};
	const getUserPerformanceGoalsList = async () => {
		try {
			setLoader(true);
			let performanceGoalCycleId =
				performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId
					? performanceGoalCycle.performanceGoalCycleId
					: 0;
			let requestParam: any = `performanceGoalCycleId=${performanceGoalCycleId}&employeeId=${
				drUserData.employeeId
			}&isMangerView=${fromTeamDetails === true}`;
			const response: any = await dispatch(getPerformanceGoalList(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setPerformanceGoalsList(response?.data.entity);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};

	const handleGoalDetailsChange = (key: string, catIndex: number, goalIndex: number, value: any) => {
		let goalCopy = { ...performanceGoalsList };
		let oldValue: any =
			goalCopy.performanceGoalSetListingCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex][key];
		if ((key === 'description' || key === 'descriptionDate') && oldValue !== value) {
			setIsDataChanged(true);
		}
		goalCopy.performanceGoalSetListingCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex][key] = value;
		goalCopy.performanceGoalSetListingCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex][
			'isDescriptionChanged'
		] = true;
		goalCopy.performanceGoalSetListingCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isError'] =
			false;

		setPerformanceGoalsList(goalCopy);
	};
	const showSavePopup = (isDraft = false, status: number) => {
		let formData: any = validateForm(isDraft);
		if (!formData.formError) {
			setOpenDeleteDialog({ open: true, isDraft: isDraft, status: status });
		}
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			saveUserGoalClick(openDeleteDialog.isDraft, openDeleteDialog.status);
		}
		setOpenDeleteDialog({ open: false, isDraft: false, status: 0 });
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.scrollHeight;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				//window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	const validateForm = (isDraft = false) => {
		let postGoalData: any[] = [];
		let formError: boolean = false;
		let expandListCopy = [...expandedGoalList];
		let goalCopy = { ...performanceGoalsList };
		let scrollToId = '';
		goalCopy.performanceGoalSetListingCategories.forEach((catData: any) => {
			catData.performanceGoalSetListingFormDetails.forEach((goalData: any) => {
				if (
					goalData.isMandatory &&
					((goalData?.questionType !== 5 && goalData?.questionType !== 4 && goalData.description === '') ||
						(goalData?.questionType == 4 && !goalData.descriptionDate) ||
						(goalData?.questionType == 5 && (!goalData.descriptionDate || !goalData.description)))
				) {
					setDescripError(true);
					if (!isDraft) {
						goalData.isError = true;
						formError = true;
						const elementIndex = expandListCopy.indexOf(catData.performanceGoalLinkedFormCategoryId);
						if (elementIndex === -1) {
							expandListCopy.push(catData.performanceGoalLinkedFormCategoryId);
						}
						if (scrollToId === '') {
							scrollToId = 'performanceGoalLinkedFormDetailId' + goalData.performanceGoalLinkedFormDetailId;
						}
					}
				}
				postGoalData.push({
					performanceGoalLinkedFormDetailId: goalData.performanceGoalLinkedFormDetailId,
					description: String(goalData.description),
					isDescriptionChanged: goalData.isDescriptionChanged || false,
					descriptionDate: goalData.descriptionDate ? formatFeedbackDate(new Date(goalData.descriptionDate)) : null,
				});
			});
		});
		if (scrollToId !== '') {
			scrollTo(scrollToId);
		}
		setExpandedGoalList(expandListCopy);
		setPerformanceGoalsList(goalCopy);
		return { formError, postGoalData };
	};
	const saveUserGoalClick = async (isDraft = false, status: number) => {
		try {
			let formData: any = validateForm(isDraft);
			let postGoalData: any[] = formData.postGoalData;
			let formError: boolean = formData.formError;
			const requestParams = {
				performanceGoalSetId: performanceGoalsList.performanceGoalSetId,
				employeeId: fromTeamDetails ? drUserData.employeeId : userDetail.employeeId,
				reportingTo: fromTeamDetails ? userDetail.employeeId : userDetail.reportingTo ? userDetail.reportingTo : 0,
				performanceGoalCycleId:
					performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId
						? performanceGoalCycle.performanceGoalCycleId
						: 0,
				status: isDraft ? 1 : status,
				performanceGoalSetDetailRequest: postGoalData,
			};
			if (!formError || isDraft) {
				const response: any = await dispatch(saveUserGoalApi(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					setPerformanceGoalsList(response?.data.entity);
					getUserPerformanceGoalsList();
					enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				} else {
					//setLoader(false);
				}
				setIsDataChanged(false);
			}
		} catch (error) {}
	};

	return (
		<>
			{loader ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : (
				<div id='createLinkPopupDetail' ref={ref}>
					{fromTeamDetails ? (
						<Box className='user-goal-section-head' id='user-goal-section-head-id'>
							<Box className='user-goal-section-head-left'>
								<Box className='back-to-main' id='back-to-main'>
									<Typography variant='body2' onClick={() => props.backClicked(false)}>
										<Link id='back-btn'>
											<BackArrow /> {t('back')}
										</Link>
									</Typography>
								</Box>
								<Box className='user-info v-center'>
									<Box className='user-img'>
										{drUserData?.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${drUserData?.firstName} ${drUserData?.lastName}`}
												src={drUserData?.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: drUserData?.firstName,
													lastName: drUserData?.lastName,
													fullName: drUserData?.fullname,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>
											{drUserData.firstName} {drUserData.lastName}
										</Typography>
									</Box>
								</Box>
								{performanceGoalsList?.statusName ? (
									<Chip
										className={
											performanceGoalsList?.status === Enums.ZERO
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.ONE
												? 'draft-chip'
												: performanceGoalsList?.status === Enums.TWO
												? 'lagging-chip'
												: performanceGoalsList?.status === Enums.THREE
												? 'default-grey-chip'
												: performanceGoalsList?.status === Enums.FOUR
												? 'on-track-chip'
												: ''
										}
										label={performanceGoalsList?.statusName}
									/>
								) : (
									<></>
								)}
								{performanceGoalsList?.isAlertMessageVisible && (
									<Box className='goals-remaining-days'>
										<AlertInfoIcon />
										<Typography variant='h6'>{performanceGoalsList?.alterMessage}</Typography>
									</Box>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
									<Tooltip title={t('goalSettingDuration')} arrow>
										<Box className='user-goals-setting-duration'>
											<CalendarIcon />
											<Typography variant='h6'>{`${getMonthDateYearTime(
												performanceGoalsList?.settingDurationStartDate
											)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
										</Box>
									</Tooltip>
								)}
								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					) : (
						<Box className='user-goal-section-head'>
							<Box className='user-goal-section-head-left'>
								{performanceGoalsList?.performanceGoalSetListingCategories?.length > 0 && (
									<>
										<Typography variant='h2'>{t('goalsLabel')}</Typography>

										{fromTeamDetails ||
											(performanceGoalsList?.statusName && (
												<Chip
													className={
														performanceGoalsList?.status === Enums.ZERO
															? 'default-grey-chip'
															: performanceGoalsList?.status === Enums.ONE
															? 'draft-chip'
															: performanceGoalsList?.status === Enums.TWO
															? 'lagging-chip'
															: performanceGoalsList?.status === Enums.THREE
															? 'default-grey-chip'
															: performanceGoalsList?.status === Enums.FOUR
															? 'on-track-chip'
															: ''
													}
													label={performanceGoalsList?.statusName}
												/>
											))}
										{performanceGoalsList?.isAlertMessageVisible ? (
											<Box className='goals-remaining-days'>
												<AlertInfoIcon />
												<Typography variant='h6'>{performanceGoalsList?.alterMessage}</Typography>
											</Box>
										) : (
											<></>
										)}
									</>
								)}
							</Box>
							<Box className='user-goal-section-head-right'>
								{performanceGoalsList?.settingDurationStartDate && performanceGoalsList?.settingDurationEndDate && (
									<Tooltip title={t('goalSettingDuration')} arrow>
										<Box className='user-goals-setting-duration'>
											<CalendarIcon />
											<Typography variant='h6'>{`${getMonthDateYearTime(
												performanceGoalsList?.settingDurationStartDate
											)} - ${getMonthDateYearTime(performanceGoalsList?.settingDurationEndDate)}`}</Typography>
										</Box>
									</Tooltip>
								)}
								{performanceGoalsList?.goalFormName && (
									<Box className='user-goal-form-name'>
										<KpiIcon />
										<Typography variant='h6'>{performanceGoalsList?.goalFormName}</Typography>
									</Box>
								)}
							</Box>
						</Box>
					)}
					{!performanceGoalsList?.performanceGoalSetListingCategories ||
					performanceGoalsList?.performanceGoalSetListingCategories?.length === 0 ? (
						<Box className='welcome-content performance-goals-welcome' id='performance-goals-welcome'>
							<Box textAlign='center' className='pad-container'>
								<Typography variant='h2'>{t('performanceGoalsNoCycleData1')}</Typography>
								<Typography>{t('performanceGoalsNoCycleData2')}</Typography>
								<AnimatedIcon
									className=''
									width='516'
									type='image/svg+xml'
									alt='WelcomeImage'
									imagePath={WelcomeImage}
								/>
							</Box>
						</Box>
					) : (
						<Box className='user-goals-section-content'>
							{performanceGoalsList?.performanceGoalSetListingCategories &&
							performanceGoalsList?.performanceGoalSetListingCategories?.length ? (
								performanceGoalsList?.performanceGoalSetListingCategories?.map((item: any, catIndex: number) => (
									<Box className='user-goals-section-list-panel'>
										<Box className='user-goals-section-list-panel-inner'>
											<Box className='user-goals-section-name-panel'>
												<List disablePadding>
													<ListItem className='user-goals-col1'>
														<Box className='user-goal-title'>
															<Tooltip arrow title={item.categoryName} classes={{ popper: 'tooltip-layout-auto' }}>
																<Typography variant='h4'>{item.categoryName}</Typography>
															</Tooltip>
														</Box>
														<Box className='user-goals-section-actions'>
															<OKRButton
																className={`btn-blue-chip ${
																	expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																		? t('expendLabel').toLowerCase()
																		: t('collapseLabel').toLowerCase()
																}`}
																icon={<RightArrowIcon />}
																text={`${item?.performanceGoalSetListingFormDetails?.length} ${t('goalsLabel')}`}
																id={`goals-toggle-btn`}
																handleClick={(e) => handleExpandGoals(e, item)}
															/>
															<OKRButton
																title={t('categoryWeightage')}
																className='btn-oval-shape'
																text={`${item.weightage}%`}
															/>
															<Box
																className={`user-goals-sec-hvr-actions ${
																	expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																		? 'hover-active'
																		: ''
																}`}
															>
																<OKRButton
																	className={`attachments-btn`}
																	icon={<AttachmentIcon />}
																	title={`${t('attachmentsLabel')}`}
																	handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'attachments')}
																/>
																<OKRButton
																	className={`conversation-btn`}
																	wrapClassName={'conversation-btn-wrap'}
																	icon={
																		<Badge
																			variant='dot'
																			className='dot-bubble'
																			overlap='circular'
																			invisible={!item.isConversationRedDotAlert ? true : false}
																		>
																			<ConversationIcon />
																		</Badge>
																	}
																	title={`${t('conversationLabel')} ${
																		item.isConversationRedDotAlert ? `(${item.conversationRedDotAlert})` : ''
																	}`}
																	handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'conversation')}
																/>
															</Box>
														</Box>
													</ListItem>
												</List>
											</Box>
											{item?.performanceGoalSetListingFormDetails &&
											item?.performanceGoalSetListingFormDetails.length ? (
												item?.performanceGoalSetListingFormDetails?.map((goalList: any, goalIndex: number) => (
													<Collapse
														in={expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1}
														className='user-goals-list-panel'
													>
														<Box
															className='user-goals-list-panel-inner'
															id={`${'performanceGoalLinkedFormDetailId' + goalList.performanceGoalLinkedFormDetailId}`}
														>
															<Box className='user-goal-name'>
																<Tooltip arrow title={goalList.name} classes={{ popper: 'tooltip-layout-auto' }}>
																	<Typography variant='h4' className='font-weight-normal'>
																		{goalList.name}
																	</Typography>
																</Tooltip>
															</Box>
															{performanceGoalsList?.isEditable && goalList?.questionType === 2 ? (
																<Box className={`user-goal-description ${goalList.isError ? 'helperText-bottom' : ''}`}>
																	<CommonEditorBox
																		placeholderText={`${t('allDetails')} ${
																			goalList.isMandatory === true ? '(mandatory)' : ''
																		}`}
																		handleEditorChange={(value: string) => {
																			handleGoalDetailsChange('description', catIndex, goalIndex, value);
																			setEditorText(value);
																			setDescripError(false);
																		}}
																		editorText={goalList.description}
																	/>
																	{(goalList.isError && (
																		<Typography className='error-field'>
																			<ErrorIcon />
																			{t('detailsRequiredMssg')}
																		</Typography>
																	)) ||
																		''}
																</Box>
															) : performanceGoalsList?.isEditable && goalList?.questionType === 3 ? (
																<Box className={`user-goal-number ${goalList.isError ? 'helperText-bottom' : ''}`}>
																	<TextField
																		fullWidth
																		className='helperText-bottom'
																		placeholder={`${t('addNumber')} ${
																			goalList.isMandatory === true ? '(mandatory)' : ''
																		}`}
																		name={t('addNumber')}
																		InputLabelProps={{
																			shrink: true,
																		}}
																		inputProps={{
																			//maxLength: 5,
																			inputType: 'numberFieldProgress',
																			inputComponent: NumberFormatComponent,
																		}}
																		InputProps={{
																			'aria-label': t('addNumber'),
																			disableUnderline: true,
																			inputComponent: NumberFormatComponent,
																		}}
																		error={goalList.isError || false}
																		helperText={
																			goalList.isError ? (
																				<>
																					<ErrorIcon /> {t('goalsTargetRequiredMssg')}
																				</>
																			) : null
																		}
																		onChange={(e: any) => {
																			handleGoalDetailsChange('description', catIndex, goalIndex, e.target.value);
																		}}
																		defaultValue={goalList.description}
																		//value={goalList.number}
																	/>
																</Box>
															) : performanceGoalsList?.isEditable && goalList?.questionType === 4 ? (
																<Box className={`user-goal-date ${goalList.isError ? 'helperText-bottom' : ''}`}>
																	<ReactDateRangePicker
																		type={'ContributorList'}
																		name='timeframeStartDate'
																		selectedCycleDetails={{}}
																		startDateValue={goalList.descriptionDate ? goalList.descriptionDate : null}
																		minimumDate={minDateValue}
																		maximumDate={maxDateValue}
																		handleDateSelection={(range: any, start: any, end: any) => {
																			if (range) {
																				handleGoalDetailsChange('descriptionDate', catIndex, goalIndex, range);
																			}
																		}}
																		isDateRangeTextFieldVisible={true}
																		isOnlyDueDateVisible={false}
																		allCycleDetails={[]}
																		isCycleVisible={false}
																		isEndOfMonthVisible={false}
																		numberOfCalendars={Enums.ONE}
																		selectionType={'single'}
																		selectedDateRange={goalList.descriptionDate || null}
																		setSelectedDateRange={(range: any) => {
																			if (range) {
																				//setSelectedTimeframeStartDateRange(range);
																			}
																		}}
																		calendarTitle={t('singleDateStartDateTitle')}
																		placeholderText={`${t('addDate')} ${
																			goalList.isMandatory === true ? '(mandatory)' : ''
																		}`}
																		isDatePickerDisable={!performanceGoalsList?.isEditable}
																	/>
																	{(goalList.isError && (
																		<Typography className='error-field'>
																			<ErrorIcon />
																			{t('goalsDateReuiredMssg')}
																		</Typography>
																	)) ||
																		''}
																</Box>
															) : performanceGoalsList?.isEditable && goalList?.questionType === 5 ? (
																<Box className={`user-goal-number-date`}>
																	<Box className='helperText-bottom'>
																		<TextField
																			placeholder={`${t('addNumber')} ${
																				goalList.isMandatory === true ? '(mandatory)' : ''
																			}`}
																			name={t('addNumber')}
																			InputLabelProps={{
																				shrink: true,
																			}}
																			inputProps={{
																				maxLength: 20,
																				inputType: 'numberFieldProgress',
																				inputComponent: NumberFormatComponent,
																			}}
																			InputProps={{
																				'aria-label': t('addNumber'),
																				disableUnderline: true,
																				inputComponent: NumberFormatComponent,
																			}}
																			error={goalList.isError || false}
																			helperText={
																				goalList.isError && !goalList.description ? (
																					<>
																						<ErrorIcon /> {t('goalsTargetRequiredMssg')}
																					</>
																				) : null
																			}
																			onChange={(e: any) => {
																				handleGoalDetailsChange('description', catIndex, goalIndex, e.target.value);
																			}}
																			defaultValue={goalList.description}
																		/>
																	</Box>
																	<Box className='helperText-bottom'>
																		<ReactDateRangePicker
																			type={'ContributorList'}
																			name='timeframeStartDate'
																			selectedCycleDetails={{}}
																			startDateValue={goalList.descriptionDate ? goalList.descriptionDate : null}
																			minimumDate={minDateValue}
																			maximumDate={maxDateValue}
																			handleDateSelection={(range: any, start: any, end: any) => {
																				if (range) {
																					handleGoalDetailsChange('descriptionDate', catIndex, goalIndex, range);
																				}
																			}}
																			isDateRangeTextFieldVisible={true}
																			isOnlyDueDateVisible={false}
																			allCycleDetails={[]}
																			isCycleVisible={false}
																			isEndOfMonthVisible={false}
																			numberOfCalendars={Enums.ONE}
																			selectionType={'single'}
																			selectedDateRange={goalList.descriptionDate || null}
																			setSelectedDateRange={(range: any) => {
																				if (range) {
																					//setSelectedTimeframeStartDateRange(range);
																				}
																			}}
																			calendarTitle={t('singleDateStartDateTitle')}
																			placeholderText={`${t('addDate')} ${
																				goalList.isMandatory === true ? '(mandatory)' : ''
																			}`}
																			isDatePickerDisable={!performanceGoalsList?.isEditable}
																		/>
																		{(goalList.isError && !goalList.descriptionDate && (
																			<Typography className='error-field'>
																				<ErrorIcon />
																				{t('goalsDateReuiredMssg')}
																			</Typography>
																		)) ||
																			''}
																	</Box>
																</Box>
															) : goalList?.description && goalList?.questionType === 2 ? (
																<Box className='user-goal-description-saved'>
																	<Box
																		dangerouslySetInnerHTML={{
																			__html: goalList.description || '',
																		}}
																	></Box>
																</Box>
															) : goalList?.description && goalList?.questionType === 3 ? (
																<Box className='user-goal-num-date-saved'>
																	<Typography>{goalList?.description}</Typography>
																</Box>
															) : goalList?.questionType === 4 ? (
																<Box className={`${goalList.descriptionDate ? 'user-goal-num-date-saved' : ''}`}>
																	{goalList.descriptionDate ? (
																		<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
																	) : (
																		<></>
																	)}
																</Box>
															) : goalList?.questionType === 5 ? (
																<Box
																	className={`${
																		goalList?.description || goalList.descriptionDate ? 'user-goal-num-date-saved' : ''
																	}`}
																>
																	<Typography>{goalList?.description}</Typography>
																	{goalList.descriptionDate ? (
																		<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
																	) : (
																		<></>
																	)}
																</Box>
															) : (
																<></>
															)}

															<Box className='user-goals-actions'>
																<OKRButton
																	title={t('goalWeightage')}
																	className='btn-oval-shape'
																	text={`${goalList.weightage}%`}
																/>
																<Box className='user-goals-hvr-actions'>
																	<OKRButton
																		className={`attachments-btn`}
																		icon={<AttachmentIcon />}
																		title={`${t('attachmentsLabel')}`}
																		handleClick={(event: any) =>
																			handleGoalSettingsDrawerOpen(event, item, 'attachments', goalList)
																		}
																	/>
																	<OKRButton
																		className={`conversation-btn`}
																		icon={
																			<Badge
																				variant='dot'
																				className='dot-bubble'
																				overlap='circular'
																				invisible={goalList.isResponseLogRedDotAlert ? false : true}
																			>
																				<ResponseLogIcon />
																			</Badge>
																		}
																		title={t('responseLog')}
																		handleClick={(event: any) =>
																			handleGoalSettingsDrawerOpen(event, item, 'conversation', goalList, {
																				catIndex,
																				goalIndex,
																			})
																		}
																	/>
																</Box>
															</Box>
														</Box>
													</Collapse>
												))
											) : (
												<></>
											)}
										</Box>
									</Box>
								))
							) : (
								<></>
							)}
						</Box>
					)}
					{performanceGoalsList?.isEditable && performanceGoalsList?.performanceGoalSetListingCategories?.length > 0 && (
						<Box className='performance-goals-action'>
							<Box></Box>
							<Box>
								{(performanceGoalsList?.status === 0 || performanceGoalsList?.status === 1) && (
									<OKRButton
										className={'btn-link'}
										id='pip_create_saveAsDraft_form'
										text={t('saveAsDraft')}
										handleClick={() => {
											saveUserGoalClick(true, 1);
										}}
									/>
								)}
								{performanceGoalsList?.status === 2 ? (
									<>
										<OKRButton
											className={'btn-link'}
											id='pip_create_submit_form'
											text={t('reconsiderLabel')}
											handleClick={() => {
												showSavePopup(false, 3);
											}}
										/>
										<OKRButton
											className={'btn-primary'}
											id='pip_create_submit_form'
											text={t('approveLabel')}
											handleClick={() => {
												showSavePopup(false, 4);
											}}
										/>
									</>
								) : (
									<OKRButton
										className={'btn-primary'}
										id='pip_create_submit_form'
										text={t('submit')}
										handleClick={() => {
											showSavePopup(false, 2);
										}}
									/>
								)}
							</Box>
						</Box>
					)}
					{categorySettingsOpen.open && (
						<CategorySettings
							{...props}
							t={t}
							categorySettingsOpen={categorySettingsOpen}
							handleSettingsDrawerClose={handleSettingsDrawerClose}
							selectTab={currentTab}
							performanceGoalsList={performanceGoalsList}
						/>
					)}
					{goalSettingsOpen.open && (
						<GoalSettings
							{...props}
							t={t}
							goalSettingsOpen={goalSettingsOpen}
							handleGoalSettingsDrawerClose={handleGoalSettingsDrawerClose}
							selectTab={currentTab}
							performanceGoalsList={performanceGoalsList}
						/>
					)}
				</div>
			)}

			{openDeleteDialog.open && (
				<AlertDialog
					module='information'
					message={
						openDeleteDialog.status === 3
							? t('reconsiderGoal')
							: openDeleteDialog.status === 2
							? t('userApproval')
							: t('approveGoal')
					}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
