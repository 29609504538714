import React, { useEffect, useState } from 'react';
import { Box, Chip, InputAdornment, List, ListItem, TablePagination, TextField, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CreateGoalReview } from './CreateGoalReview';
import '../../../../styles/pages/admin/settings/goal-review-settings/goal-review-settings.scss';
import LinearLoader from '../../../Common/Loader';
import { GoalReviewsSettingsTab } from './GoalReviewsSettingsTab';
import { SettingsTabPanel } from '..';
import { GoalReviewList } from './GoalReviewLists';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../../../config/enums';
import { CrossIcon, DownloadIcon, SearchIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReviewWorkflowReversalList } from './GoalReviewWorkflow/ReviewWorkflowReversalList';
import { GoalReviewWorkflow } from './GoalReviewWorkflow';
import { ReviewWorkflowReversalCycleFilter } from './GoalReviewWorkflow/ReviewWorkflowReversalCycleFilter';
import {
	exportGoalReviewCycleListReport,
	exportReviewWorkflowReversalReport,
	getReviewWorkflowReversalCycleList,
} from '../../../../action/adminSettings';
import { OKRButton } from '../../../Common/OKRButton';

export const GoalReviewSettings: React.FC<any> = (props: any) => {
	const {
		setTabSelected,
		tabSelected,
		handleTabChange,
		a11yProps,
		goalReviewOpen,
		setGoalReviewOpen,
		reviewWorkflowReversalOpen,
		setReviewWorkflowReversalOpen,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loader, setLoader] = useState<any>(false);
	const [pageIndex, setPageIndex] = useState<any>(1);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [goalCycleSearchValue, setGoalCycleSearchValue] = useState<string>('');
	const [goalCycleSearchTextArray, setGoalCycleSearchTextArray] = useState<string>('');
	const { paginationData } = useSelector((state: any) => state?.adminSettingReducer);
	const [reviewWorkflowReversalCycleList, setReviewWorkflowReversalCycleList] = useState([]);
	const [selectedReviewWorkflowFilterCycleId, setSelectedReviewWorkflowFilterCycleId] = useState<any>(0);
	let defaultFinalSettings: any[] = [];
	const [finalFilterCondition, setFinalFilterCondition] = useState<any>(defaultFinalSettings);

	useEffect(() => {
		getReviewWorkflowReversalCycle();
	}, []);

	const handleGoalCycleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
	};

	const handleGoalCycleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
	};

	const goalCycleSearchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleGoalCycleSearch(e.target.value);
			}
			setGoalCycleSearchValue('');
		}
	};
	const handleGoalCycleChange = (e: any) => {
		setGoalCycleSearchValue(e.target.value);
	};
	const handleGoalCycleSearch = (value: any) => {
		setPageIndex(1);
		setGoalCycleSearchTextArray(value);
		setLoader(true);
	};

	const clearGoalCycleSearch = () => {
		setPageIndex(1);
		setGoalCycleSearchTextArray('');
	};
	const handleGoalReviewAction = (record: any, type: string) => {
		setGoalReviewOpen({ open: true, data: { ...record, isEdit: true } });
	};

	const getReviewWorkflowReversalCycle = async () => {
		try {
			const response: any = await dispatch(getReviewWorkflowReversalCycleList());
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entityList } = response.data;
					if (entityList) {
						let records = entityList;
						if (records && records.length) {
							let recordsCpy = records.map((item: any) => {
								return {
									...item,
									performanceCycleId: item.performanceGoalReviewCycleId,
									name: item.performanceGoalReviewCycleIdName,
								};
							});
							setReviewWorkflowReversalCycleList(recordsCpy);
							//setGoalSettingReversalReportCycle(recordsCpy[0]);
						} else {
							setReviewWorkflowReversalCycleList([]);
						}
					} else {
						setReviewWorkflowReversalCycleList([]);
					}
				} else {
					setReviewWorkflowReversalCycleList([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleWorkflowFilterChanged = (value: number) => {
		setSelectedReviewWorkflowFilterCycleId(value);
	};

	const applyFilter = (filterData: any) => {
		setFinalFilterCondition(filterData);
	};

	const handleExportGoalReviewCycleReport = async () => {
		try {
			const response: any = await dispatch(exportGoalReviewCycleListReport(''));
			if (response) {
				const defaultName = 'goalReviewCycleList.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			// setListLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const handleReviewWorkflowReversalReport = async () => {
		try {
			const response: any = await dispatch(exportReviewWorkflowReversalReport(''));
			if (response) {
				const defaultName = 'reviewWorkflowReversalList.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			// setListLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap-type1'>
					<LinearLoader />
				</Box>
			)}
			{
				//herer
			}
			{goalReviewOpen && goalReviewOpen.open ? (
				<CreateGoalReview
					setTabSelected={setTabSelected}
					setGoalReviewOpen={setGoalReviewOpen}
					goalReviewOpen={goalReviewOpen}
				/>
			) : reviewWorkflowReversalOpen && reviewWorkflowReversalOpen.open ? (
				<GoalReviewWorkflow
					setTabSelected={setTabSelected}
					setReviewWorkflowReversalOpen={setReviewWorkflowReversalOpen}
				/>
			) : (
				<Box className='goal-review-settings-area'>
					<Box className='admin-tabpanel-inner'>
						<Box className='admin-tabpanel-head'>
							<Box className='admin-tabpanel-head-left'>
								<GoalReviewsSettingsTab
									a11yProps={a11yProps}
									tabSelected={tabSelected}
									handleTabChange={(event: any, newValue: Number) => {
										handleTabChange(event, newValue);
									}}
								/>
							</Box>
							<Box className='admin-tabpanel-head-right align-goals-head-right'>
								<>
									{paginationData && paginationData?.totalRecords && (
										<Box>
											<TablePagination
												component='div'
												className='user-list-pagination'
												count={paginationData?.totalRecords}
												page={pageIndex > 1 ? pageIndex - 1 : 0}
												onPageChange={handleGoalCycleChangePage}
												rowsPerPage={pageSize}
												onRowsPerPageChange={handleGoalCycleChangeRowsPerPage}
												SelectProps={{
													MenuProps: {
														PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
													},
												}}
											/>
										</Box>
									)}
									{paginationData && paginationData?.totalRecords && tabSelected === 1 && (
										<ReviewWorkflowReversalCycleFilter
											reviewWorkflowReversalCycleList={reviewWorkflowReversalCycleList}
											handleWorkflowFilterChanged={handleWorkflowFilterChanged}
											selectedReviewWorkflowFilterCycleId={selectedReviewWorkflowFilterCycleId}
											setFinalFilterCondition={applyFilter}
											finalFilterCondition={finalFilterCondition}
										/>
									)}
									{!(paginationData && paginationData?.totalRecords) &&
									goalCycleSearchValue === '' &&
									goalCycleSearchTextArray.length === 0 ? (
										<></>
									) : (
										<Box className='user-search'>
											<TextField
												className='search-text-field'
												id='user-src-field'
												placeholder={t('cycleSearchPlaceHolder')}
												fullWidth
												value={goalCycleSearchValue}
												onChange={(e) => handleGoalCycleChange(e)}
												onKeyDown={(e) => goalCycleSearchClicked(e)}
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<SearchIcon />
														</InputAdornment>
													),
												}}
											/>
											{goalCycleSearchTextArray && (
												<Box className='user-search-chip'>
													<List>
														<ListItem>
															{goalCycleSearchTextArray.length > 20 ? (
																<Tooltip title={goalCycleSearchTextArray} arrow>
																	<Chip
																		label={goalCycleSearchTextArray.substring(0, 20) + '...'}
																		onDelete={clearGoalCycleSearch}
																		deleteIcon={
																			<span>
																				<CrossIcon />
																			</span>
																		}
																	/>
																</Tooltip>
															) : (
																<Chip
																	label={goalCycleSearchTextArray}
																	onDelete={clearGoalCycleSearch}
																	deleteIcon={
																		<span>
																			<CrossIcon />
																		</span>
																	}
																/>
															)}
														</ListItem>
													</List>
												</Box>
											)}
										</Box>
									)}
									{paginationData?.totalRecords > 0 && (
										<Box className='align-goals-download'>
											<OKRButton
												className='btn-circle-white download-btn'
												icon={<DownloadIcon />}
												title={
													tabSelected === 1
														? t('downloadReveiwWorkflowReversalDetails')
														: t('downloadGoalReviewCycleDetails')
												}
												handleClick={
													tabSelected === 1 ? handleReviewWorkflowReversalReport : handleExportGoalReviewCycleReport
												}
											></OKRButton>
										</Box>
									)}
								</>
							</Box>
						</Box>
						<SettingsTabPanel value={tabSelected} index={0}>
							<GoalReviewList
								goalCycleSearchTextArray={goalCycleSearchTextArray}
								clearGoalCycleSearch={clearGoalCycleSearch}
								pageIndex={pageIndex}
								pageSize={pageSize}
								setLoader={setLoader}
								handleGoalReviewAction={handleGoalReviewAction}
							/>
						</SettingsTabPanel>
						<SettingsTabPanel value={tabSelected} index={1}>
							<ReviewWorkflowReversalList
								goalCycleSearchTextArray={goalCycleSearchTextArray}
								pageIndex={pageIndex}
								pageSize={pageSize}
								setLoader={setLoader}
								selectedReviewWorkflowFilterCycleId={selectedReviewWorkflowFilterCycleId}
								finalFilterCondition={finalFilterCondition}
							/>
						</SettingsTabPanel>
					</Box>
				</Box>
			)}
		</>
	);
};
