import { Box, Button, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkPermission } from '../../../config/rolePermission';
import { AddIconSvg, CopyOkrsIconColored, DashIcon, PerformanceColoredIcon } from '../../../config/svg/CommonSvg';
import {
	addNewRole,
	addNewUsers,
	createTeams,
	supportModulePerformAssessment,
	supportModuleRecognize,
} from '../AdminConstant';
import { AdminListingTabs } from '../AdminListingTabs';
import { PopperMenu } from '../CommonComponent/PopperMenu';
import {
	AddRoleIcon,
	AddTeamsIcon,
	AddUserIcon,
	AssessmentFormsColoredIcon,
	PerformReviewIcon,
	ResetTypeIcon,
} from '../../../config/svg/CommonSvgIcon';
import { DialogComponent } from '../CommonComponent/DialogComponent';
import { checkSupportPermissionType } from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	GoalFormColoredIcon,
	GoalReviewColoredIcon,
	GoalWorkflowColoredIcon,
	GoalsCycleColoredIcon,
	ImportOkrsIcon,
	ObjectiveIcon,
} from '../../../config/svg/MyGoalSvg';
import { openAdminCopyOkr } from '../../../action/adminDefaultOkr';

export const SubHeader: React.FC<any> = (props) => {
	const {
		tabSelected,
		handleMenuClick,
		getPermissionResult,
		licenseDetails,
		setOrgDropdownSelected,
		orgDropdownSelected,
		handleAddPerformanceReviewBtnClick,
		setOpenAssessmentForm,
		setAssessmentTabSelected,
		setOpenRecognizeDrawer,
		handleTabMenuChange,
		handleAddAssessmentFormBtnClick,
		handleClickDefaultOkr,
		setDefaultOkrOpen,
		defaultOkrOpen,
		handleImportDrawerOpen,
		goalFormOpen,
		setGoalFormOpen,
		handleGoalFormOpen,
		handleGoalCycleOpen,
		handleBulkGoalFormOpen,
		openImportOkr,
		handleWorkflowReversalOpen,
		setGoalReviewOpen,
		handleGoalReviewOpen,
		handleReviewWorkflowReversalOpen,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState(false);
	const { selectionValue } = useSelector((state: any) => state?.adminSettingReducer);
	const { currentSettingTab, defaultOkrTagName } = useSelector((state: any) => state.commonReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selectedPastCycle } = useSelector((state: any) => state.adminDefaultOkrReducer);
	const { goalCycleFormCount } = useSelector((state: any) => state.adminSettingReducer);
	useEffect(() => {
		// if (tabSelected) {
		setAnchorEl(null);
		setOpen(false);
		//}
	}, [tabSelected]);

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};
	const handleClose = (e: any) => {
		setAnchorEl(null);
		setOpen(false);
	};
	const handleClickRecognize = (event: any) => {
		setOpenRecognizeDrawer(true);
	};
	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-admin-subheader');
		if (window.pageYOffset >= 88) {
			objElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement?.classList.remove('sticky-header');
		}
	};

	return (
		<Box className='page-subheader' id='page-admin-subheader'>
			<Box className='page-subheader-inner'>
				<Box className='page-subheader-left'>
					<Box className='page-subheader-title'>
						<Typography variant='h2'>{t('admin')}</Typography>
					</Box>
					<AdminListingTabs
						tabSelected={props.tabSelected}
						handleTabChange={props.handleTabChange}
						setOrgDropdownValue={props.setOrgDropdownValue}
						setOrgDropdownSelected={setOrgDropdownSelected}
						orgDropdownSelected={orgDropdownSelected}
						handleClose={handleClose}
						handleTabMenuChange={handleTabMenuChange}
						handleAddPerformanceReviewBtnClick={handleAddPerformanceReviewBtnClick}
						setDefaultOkrOpen={setDefaultOkrOpen}
						setGoalFormOpen={setGoalFormOpen}
						setGoalReviewOpen={setGoalReviewOpen}
						{...props}
					/>
				</Box>
				{!selectedPastCycle && (
					<Box className='page-subheader-right' id='admin-plus-icon'>
						{props.tabSelected !== 3 && (
							<>
								{checkPermission(getPermissionResult?.employeePermissions, addNewUsers) ||
								checkPermission(getPermissionResult?.employeePermissions, addNewRole) ||
								checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
									<>
										<Button
											className={open ? 'add-btn active' : 'add-btn'}
											color='primary'
											onClick={handleClick}
											id={'quick-link-add'}
										>
											<AddIconSvg />
										</Button>
										<PopperMenu
											{...props}
											anchorEl={anchorEl}
											open={open}
											handleClose={handleClose}
											children={
												<>
													{checkPermission(getPermissionResult?.employeePermissions, addNewUsers) ? (
														<ListItem
															id='add-user-tab'
															onClick={(e: any) => {
																handleClose(e);
																if (!licenseDetails || licenseDetails.isAddUserAllow === false) {
																	setModalOpen(true);
																} else {
																	handleMenuClick(e, 'addNewUsers');
																}
															}}
														>
															<ListItemAvatar>
																<AddUserIcon />
															</ListItemAvatar>
															<ListItemText primary={t('addUserLabel')} />
														</ListItem>
													) : (
														<></>
													)}
													{checkPermission(getPermissionResult.employeePermissions, createTeams) ? (
														<ListItem
															id='add-team-tab'
															onClick={(e: any) => {
																handleClose(e);
																handleMenuClick(e, 'addNewTeam');
															}}
														>
															<ListItemAvatar>
																<AddTeamsIcon />
															</ListItemAvatar>
															<ListItemText primary={t('addTeamsLabel')} />
														</ListItem>
													) : (
														<></>
													)}
													{checkPermission(getPermissionResult?.employeePermissions, addNewRole) ? (
														<ListItem
															id='add-role-tab'
															onClick={(e: any) => {
																handleClose(e);
																handleMenuClick(e, 'addNewRole');
															}}
														>
															<ListItemAvatar>
																<AddRoleIcon />
															</ListItemAvatar>
															<ListItemText primary={t('addRolesLabel')} />
														</ListItem>
													) : (
														<></>
													)}
												</>
											}
											connectorIcon={true}
										></PopperMenu>
									</>
								) : (
									<></>
								)}
							</>
						)}
						{props.tabSelected === 3 && checkSupportPermissionType(supportModuleRecognize) && (
							<>
								{currentSettingTab === 'recognition' ? (
									<Button className={'add-btn'} color='primary' onClick={handleClickRecognize} id={'add-badge-link'}>
										<AddIconSvg />
									</Button>
								) : (
									<></>
								)}
							</>
						)}
						{props.tabSelected === 3 && checkSupportPermissionType(supportModulePerformAssessment) && (
							<>
								{currentSettingTab === 'performReview' ? (
									<Button
										className={open ? 'add-btn active' : 'add-btn'}
										color='primary'
										onClick={handleClick}
										id={'quick-link-add'}
									>
										<AddIconSvg />
									</Button>
								) : (
									<></>
								)}
								{currentSettingTab !== 'alignGoals' && currentSettingTab !== 'goalReviews' && (
									<PopperMenu
										{...props}
										anchorEl={anchorEl}
										open={open}
										handleClose={handleClose}
										children={
											<>
												<ListItem
													id='add-perform-tab'
													onClick={(e: any) => {
														handleClose(e);
														handleAddPerformanceReviewBtnClick(e, true, '');
													}}
												>
													<ListItemAvatar>
														<PerformanceColoredIcon />
													</ListItemAvatar>
													<ListItemText primary={t('performanceCycleLevel')} />
												</ListItem>
												{/* <ListItem
											id='add-review-tab'
											onClick={(e: any) => {
												handleClose(e);
												setOpenAssessmentForm({ open: true, ratingFormType: 'Add' });
												setAssessmentTabSelected({ activeTab: 1 });
											}}
										>
											<ListItemAvatar>
												<ReviewTempIcon />
											</ListItemAvatar>
											<ListItemText primary={t('addReviewTemplate')} />
										</ListItem> */}
												<ListItem
													id='add-review-tab'
													onClick={(e: any) => {
														handleClose(e);
														handleAddAssessmentFormBtnClick(e, true, '');
													}}
												>
													<ListItemAvatar>
														<AssessmentFormsColoredIcon />
													</ListItemAvatar>
													<ListItemText primary={t('assessmentFormLevel')} />
												</ListItem>
											</>
										}
										connectorIcon={true}
									></PopperMenu>
								)}
							</>
						)}
						{props.tabSelected === 2 && orgDropdownSelected === 0 ? (
							<Box className='chart-zoom-buttons'>
								<Tooltip arrow title={t('zoomInLabel')} placement={'left-end'}>
									<button className='zoom-button' id='zoom-in'>
										<AddIconSvg />
									</button>
								</Tooltip>
								<Tooltip arrow title={t('zoomOutLabel')} placement={'left-end'}>
									<button className='zoom-button' id='zoom-out'>
										<DashIcon />
									</button>
								</Tooltip>
								<Tooltip arrow title={t('resetLabel')} placement={'left-end'}>
									<button className='zoom-button reset-btn' id='zoom-extent'>
										<ResetTypeIcon />
									</button>
								</Tooltip>
							</Box>
						) : (
							<></>
						)}
						{props.tabSelected === 3 && currentSettingTab === 'align' && !defaultOkrOpen ? (
							<>
								<Button
									className={open ? 'add-btn active' : 'add-btn'}
									color='primary'
									onClick={handleClick}
									id={'quick-link-add'}
								>
									<AddIconSvg />
								</Button>
								<PopperMenu
									{...props}
									anchorEl={anchorEl}
									open={open}
									handleClose={handleClose}
									children={
										<>
											<ListItem id='create-default-okr-tab' onClick={handleClickDefaultOkr}>
												<ListItemAvatar>
													<ObjectiveIcon />
												</ListItemAvatar>
												<ListItemText primary={`${t('createNewDefaultOKR')} ${defaultOkrTagName || ''}`} />
											</ListItem>
											<ListItem id='import-okrs-tab' onClick={handleImportDrawerOpen}>
												<ListItemAvatar>
													<ImportOkrsIcon />
												</ListItemAvatar>
												<ListItemText primary={t('importOkrs')} />
											</ListItem>
											<ListItem
												id='copy-okrs-tab'
												onClick={() => {
													dispatch(openAdminCopyOkr(true));
												}}
											>
												<ListItemAvatar>
													<CopyOkrsIconColored />
												</ListItemAvatar>
												<ListItemText primary={t('copyOKRs')} />
											</ListItem>
										</>
									}
									connectorIcon={true}
								></PopperMenu>
							</>
						) : (
							<></>
						)}
						{props.tabSelected === 3 && currentSettingTab === 'alignGoals' ? (
							<>
								<Button
									className={open ? 'add-btn active' : 'add-btn'}
									color='primary'
									onClick={handleClick}
									id={'quick-link-add'}
								>
									<AddIconSvg />
								</Button>
								<PopperMenu
									{...props}
									anchorEl={anchorEl}
									open={open}
									handleClose={handleClose}
									children={
										<>
											<ListItem
												id='add-goal-cycle-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleGoalCycleOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<GoalsCycleColoredIcon />
												</ListItemAvatar>
												<ListItemText primary={t('goalCycleLabel')} />
											</ListItem>
											<ListItem
												id='add-goal-form-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleGoalFormOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<GoalFormColoredIcon />
												</ListItemAvatar>
												<ListItemText primary={t('goalFormLabel')} />
											</ListItem>
											<ListItem
												id='add-workflow-reversal-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleWorkflowReversalOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<GoalWorkflowColoredIcon />
												</ListItemAvatar>
												<ListItemText primary={t('workflowReversal')} />
											</ListItem>
											<ListItem
												id='add-bulk-goal-form-tab'
												onClick={(e: any) => {
													handleClose(e);
													handleBulkGoalFormOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<ImportOkrsIcon />
												</ListItemAvatar>
												<ListItemText primary={t('addBulkGoalFormUpload')} />
											</ListItem>
										</>
									}
									connectorIcon={true}
								/>
							</>
						) : (
							<></>
						)}
						{props.tabSelected === 3 && currentSettingTab === 'goalReviews' ? (
							<>
								<Button
									className={open ? 'add-btn active' : 'add-btn'}
									color='primary'
									onClick={handleClick}
									id={'quick-link-add'}
								>
									<AddIconSvg />
								</Button>
								<PopperMenu
									popperClassName={'popper-menu popper-menu-goal-review'}
									{...props}
									anchorEl={anchorEl}
									open={open}
									handleClose={handleClose}
									children={
										<>
											<ListItem
												id='add-review-cycle'
												onClick={(e: any) => {
													handleClose(e);
													handleGoalReviewOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<GoalReviewColoredIcon />
												</ListItemAvatar>
												<ListItemText primary={t('goalReviewsLevel')} />
											</ListItem>
											<ListItem
												id='add-review-workflow'
												onClick={(e: any) => {
													handleClose(e);
													handleReviewWorkflowReversalOpen(e, true, '');
												}}
											>
												<ListItemAvatar>
													<GoalWorkflowColoredIcon />
												</ListItemAvatar>
												<ListItemText primary={t('reviewWorkflowReversal')} />
											</ListItem>
										</>
									}
									connectorIcon={true}
								/>
							</>
						) : (
							<></>
						)}
					</Box>
				)}
			</Box>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('upgradeBufferLicenses')}
					handleCloseModal={() => {
						setModalOpen(false);
					}}
					isCancel={true}
					modalOpen={modalOpen}
				/>
			)}
		</Box>
	);
};
