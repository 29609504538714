import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Button, Tooltip, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DownloadIcon } from '../../../../config/svg/CommonSvg';
import { PipDetailPdf } from '../PipDetailPdf';
import { getPipDetailsPdf } from '../../../../action/performanceImprovementPlan';
import { OKRButton } from '../../../Common/OKRButton';

export default function PipDownloadPdf(props) {
	const { itemDetail, setLoader, handleClose } = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const textInput = useRef();

	const [pipPdfDetail, setPipPdfDetail] = useState(null);
	const [show, setShow] = useState(false);
	//const [loader, setLoader] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (pipPdfDetail) {
			setShow(true);
			setTimeout(() => {
				textInput.current.click();
				setLoader(false);
			}, 1000);
		}
	}, [pipPdfDetail]);

	const fetchOkrDetail = async (e, item) => {
		try {
			const requestParams = itemDetail.performanceImprovementPlanId;
			const res = await dispatch(getPipDetailsPdf(requestParams));
			if (res) {
				if (res && res.data && res.data.status === 200) {
					//setShow(true);
					let data = res.data.entity;
					setPipPdfDetail(data);
				} else {
					setLoader(false);
					setShow(false);
					setPipPdfDetail('');
					enqueueSnackbar('Error occurred while downloading PIP', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
		} catch (error) {
			setLoader(false);
			setShow(false);
			setPipPdfDetail('');
			enqueueSnackbar('Error occurred while downloading PIP', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	const downloadPdf = (e, item) => {
		setLoader(true);
		fetchOkrDetail(e, item);
	};
	return (
		<Fragment>
			{/* {loader && <LinearLoader />} */}
			{/* <Tooltip title={t('downloadMOM')} arrow>
					<ListItem
						id='download_pip_details'
						onClick={(e) => {
							handleClose();
							downloadPdf(e, itemDetail);
						}}
					>
						<ListItemAvatar>
							<DownloadIcon />
						</ListItemAvatar>
						<ListItemText primary={t('download')} />
					</ListItem>
				</Tooltip> */}
			<OKRButton
				className='btn-link-type1 font-weight-bold'
				icon={<DownloadIcon />}
				text={t('download')}
				handleClick={(e) => {
					handleClose();
					downloadPdf(e, itemDetail);
				}}
			/>

			{show && pipPdfDetail && (
				<PDFDownloadLink
					document={pipPdfDetail && <PipDetailPdf data={pipPdfDetail} {...props} />}
					fileName={`PIP_${pipPdfDetail?.nomineeDetails?.firstName}_${pipPdfDetail?.nomineeDetails?.lastName}.pdf`}
					style={{ display: 'none' }}
				>
					<Tooltip title={t('download')} arrow>
						<Button className='download-btn' ref={textInput}>
							<DownloadIcon />
						</Button>
					</Tooltip>
				</PDFDownloadLink>
			)}
		</Fragment>
	);
}
