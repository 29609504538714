import React, { useEffect, useState } from 'react';
import { Avatar, Box, ClickAwayListener, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
	AcceptIcon,
	BottomArrowIcon,
	FinalRatingIcon2,
	RecommendedIcon,
	SalaryIcon,
} from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import { Enums } from '../../config/enums';
import { NoUnitsIcon } from '../../config/svg/MyGoalSvg';
import { CommentIcon, ConversationIcon, DesignationIcon, ResponseLogIcon } from '../../config/svg/CommonSvg';
import CkEditor from '../Common/CkEditor/CkEditor';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFinalRatingReview,
	getGoalAlertConversation,
	getRatingDropdownData,
	saveFinalRatingReviews,
} from '../../action/adminSettings';
import { formatFeedbackDate, getFullName, getUserName } from '../../config/utils';
import { saveFinalRatingReview } from '../../services/adminSettingsService';
import { useSnackbar } from 'notistack';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { ReviewConversationsSettings } from './ReviewConversationsSettings';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import AlertDialog from '../Common/Dialog';

export const TeamReviewFinalRating: React.FC<any> = (props) => {
	const {
		drUserData,
		users,
		handleSave,
		handleReconsider,
		finalRatingData,
		setFinalRatingData,
		finalRatingDataError,
		setFinalRatingDataError,
		ratingDropdownData,
		setRatingDropdownData,
		selectedKrDateRange,
		setSelectedKrDateRange,
		isEditable,
		fromTeamDetails,
		validateForm,
	} = props;
	const { t } = useTranslation();
	const moment = extendMoment(Moment);
	const [openFinalRating, setOpenFinalRating] = useState<Boolean>(false);
	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [currentTab, setCurrentTab] = useState<string>('');
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 18);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);

	const handleRatingDropdown = () => {
		setOpenFinalRating((prev: Boolean) => !prev);
	};
	const handleRatingClickAway = () => {
		setOpenFinalRating(false);
	};
	const handleSettingsDrawerOpen = (event: any, usersDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: usersDetails });
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};

	const handleSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setCategorySettingsOpen({ open: false, data: '' });
		if (currentTab === 'conversation') {
			//getUserPerformanceGoalsList();
			dispatch(getGoalAlertConversation());
		}
	};

	const { chartData } = props;
	const columnWithGroupedChartData = {
		credits: {
			enabled: false,
		},
		chart: {
			type: 'gauge',
			plotBackgroundColor: '#416390',
			plotBackgroundImage: null,
			plotBorderWidth: 0,
			plotShadow: false,
			height: '90%',
		},
		title: {
			text: 'Score',
			style: {
				color: '#FFFFFF',
			},
		},

		pane: {
			startAngle: -90,
			endAngle: 89.9,
			background: null,
			center: ['50%', '65%'],
			size: '125%',
		},
		yAxis: {
			min: 0,
			max: finalRatingData?.scoreCalculatedOn,
			tickPixelInterval: 72,
			tickPosition: 'inside',
			tickColor: '#FFFFFF',
			tickLength: 20,
			tickWidth: 0,
			minorTickInterval: null,
			labels: {
				distance: 20,
				style: {
					fontSize: '0px',
				},
			},
			lineWidth: 0,
			plotBands: [
				{
					from: 0,
					to: finalRatingData?.scoreCalculatedOn,
					color: '#debdff',
					thickness: 25,
					borderRadius: '50%',
				},
			],
		},

		series: [
			{
				name: 'Score',
				data: [finalRatingData.changedFinalRatingId],
				tooltip: {
					valueSuffix: '',
				},
				dataLabels: {
					format: '{y}',
					borderWidth: 0,
					color: '#ffffff',

					style: {
						fontSize: '32px',
					},
				},
				dial: {
					radius: '110%',
					backgroundColor: '#CEE3FF',
					baseWidth: 12,
					baseLength: '0%',
					rearLength: '0%',
				},
				pivot: {
					backgroundColor: '#CEE3FF',
					radius: 6,
				},
			},
		],
	};
	const formDisabled = false;
	const htmlContent = '<b>Approved</b> with <i>minor feedback</i>';
	const dispatch = useDispatch();

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'reconsider') {
				handleReconsider(false, 3);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
			if (modalProps?.type === 'submit') {
				handleSave();
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	return (
		<>
			<Box className='final-ratings-card full-rectangular-card'>
				<Box className='final-ratings-head'>
					<Box className='final-ratings-head-left'>
						<Typography variant='h4' className='font-weight-normal'>
							{t('finalRatings')}
						</Typography>
						<Typography variant='subtitle2' className='subtitle3'>
							{t('comprehensiveRatingText')}
						</Typography>
					</Box>
					<Box className='final-ratings-head-right'>
						<List disablePadding>
							<ListItem>
								{isEditable && (
									<OKRButton
										icon={<ConversationIcon />}
										text={t('reviewConversation')}
										handleClick={(event: any) => handleSettingsDrawerOpen(event, drUserData, 'conversation')}
									/>
								)}
							</ListItem>
							<ListItem>
								{isEditable && (
									<OKRButton
										icon={<ResponseLogIcon />}
										text={t('finalRatingLogLabel')}
										handleClick={(event: any) => handleSettingsDrawerOpen(event, drUserData, 'responseLog')}
									/>
								)}
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className='user-final-review-info-box'>
					<Box className='user-info v-center'>
						<Box className='user-info v-center'>
							<Box className='user-img'>
								<Avatar className='avatar-default' src={drUserData?.imagePath || ''}>
									{getUserName({
										firstName: drUserData?.firstName || '',
										lastName: drUserData?.lastName || '',
										fullName: drUserData?.label || '',
									})}
								</Avatar>
							</Box>

							<Box className='user-info-details'>
								<Typography variant='h4'>{getFullName(drUserData)}</Typography>
								<Typography variant='subtitle2'>{drUserData.designation}</Typography>
							</Box>
						</Box>
					</Box>
					<Box className='user-final-review-info-row'>
						<Box className='user-final-review-info-col'>
							<Box className='pie-chart'>
								<HighchartsReact highcharts={Highcharts} options={columnWithGroupedChartData} />
							</Box>
						</Box>
						<Box className='user-final-review-info-col'>
							<Box className='user-final-review-info-col2'>
								<Box
									className={`select-rating-box ${
										finalRatingDataError && finalRatingDataError.changedFinalRatingId && 'helperText-bottom'
									}`}
								>
									<Box className='final-rating-label'>
										<FinalRatingIcon2 />
										<Typography>
											{t('ratingLabel')}
											<i style={{ color: 'red' }}>*</i>
										</Typography>
									</Box>
									<Box className='final-rating-select-box'>
										<Box className={`ques-type-selection custom-type-dropdown-top`}>
											<OKRButton
												//disabled={formDisabled}
												className={`dropdown-button button-placeholder`}
												text={`${
													finalRatingData.changedFinalRatingId && finalRatingData.name
														? finalRatingData.name
														: '-- Select --'
												}`}
												icon={<BottomArrowIcon />}
												handleClick={() => handleRatingDropdown()}
												disabled={
													finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
													!finalRatingData.isSubmitBtnVissible
												}
											/>

											{openFinalRating && (
												<ClickAwayListener onClickAway={handleRatingClickAway}>
													<Box className='ques-type-dropdown'>
														<List component='div' disablePadding>
															{ratingDropdownData.map((item: any) => (
																<ListItem
																	component='div'
																	className={`${finalRatingData?.changedFinalRatingId === item.id ? 'active' : ''}`}
																	onClick={(e) => {
																		setFinalRatingData({
																			...finalRatingData,
																			changedFinalRatingId: item.id,
																			isDropDownChanged: true,
																			name: item.name,
																		});
																		setFinalRatingDataError({
																			...finalRatingDataError,
																			changedFinalRatingId: '',
																		});
																		handleRatingClickAway();
																	}}
																>
																	<Typography>{item.name}</Typography>
																</ListItem>
															))}
														</List>
													</Box>
												</ClickAwayListener>
											)}
											{finalRatingDataError && finalRatingDataError.changedFinalRatingId && (
												<Typography className='error-field'>
													<ErrorIcon />
													{finalRatingDataError.changedFinalRatingId}
												</Typography>
											)}
										</Box>
										<Typography variant='body2'>{t('calculatedScoreText')}</Typography>
									</Box>
								</Box>
								<Box
									className={`final-rating-one-on-one-field ${
										finalRatingDataError && finalRatingDataError.oneonOneDate ? 'helperText-bottom' : ''
									} `}
								>
									<FormLabel id='one-on-one-duration' component='legend' className='form-label-required'>
										<RequestOneOnOneIcon2 />
										{t('requestOnetoOne')} <i style={{ color: 'red' }}>*</i>
									</FormLabel>
									<Box className='calendar-start-end-fields'>
										{
											<ReactDateRangePicker
												type={'joiningDate'}
												startDateValue={null}
												minimumDate={minDateValue}
												maximumDate={new Date()}
												handleDateSelection={(range: any, start: any, end: any) => {
													setSelectedKrDateRange(range);
													setFinalRatingData({ ...finalRatingData, oneonOneDate: range });

													setFinalRatingDataError({
														...finalRatingDataError,
														oneonOneDate: '',
													});
												}}
												isDateRangeTextFieldVisible={true}
												isOnlyDueDateVisible={false}
												allCycleDetails={[]}
												isCycleVisible={false}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.ONE}
												selectionType={'single'}
												placeholderText={t('selectDateLabel')}
												isDatePickerDisable={!finalRatingData.isOneToOneVissible}
												selectedDateRange={selectedKrDateRange}
												setSelectedDateRange={(val: any) => {
													if (val) {
														setSelectedKrDateRange(val);
													}
												}}
											/>
										}
										{finalRatingDataError && finalRatingDataError.oneonOneDate ? (
											<Typography className='error-field'>
												<ErrorIcon /> {'Select a publish date'}
											</Typography>
										) : (
											<></>
										)}
									</Box>
									<Typography variant='body2'>{t('reviewDiscussionText')}</Typography>
								</Box>
							</Box>
							{fromTeamDetails &&
								(finalRatingData.justificationToChangeTheRating || finalRatingData.isDropDownChanged) && (
									<Box
										className={`final-rating-justify-comment ${
											finalRatingDataError && finalRatingDataError.justificationToChangeTheRating
												? 'helperText-bottom'
												: ''
										}`}
									>
										<Box className='final-rating-label'>
											<CommentIcon />
											<Typography>
												{t('justifyCommentTitle')}
												<i style={{ color: 'red' }}>*</i>
											</Typography>
										</Box>
										<TextField
											className='justification-comment-field'
											value={finalRatingData.justificationToChangeTheRating}
											name='Justification comment'
											placeholder={t('justifyCommentPlaceholder')}
											disabled={!finalRatingData.isSubmitBtnVissible}
											onChange={(e) => {
												setFinalRatingData({ ...finalRatingData, justificationToChangeTheRating: e.target.value });
												setFinalRatingDataError({ ...finalRatingDataError, justificationToChangeTheRating: '' });
											}}
											inputProps={{ maxLength: 250 }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										{finalRatingDataError && finalRatingDataError.justificationToChangeTheRating && (
											<Typography className='error-field'>
												<ErrorIcon />
												{finalRatingDataError.justificationToChangeTheRating}
											</Typography>
										)}
									</Box>
								)}
							<Box className='user-final-review-info-col2'>
								<Box className='final-rating-eligible-box'>
									<Box className='final-rating-label'>
										<SalaryIcon />
										<Typography>
											{t('eligibleForSalaryIncrement')}
											<i style={{ color: 'red' }}>*</i>
										</Typography>
									</Box>
									<Box className='setting-box-list'>
										<OKRButton
											className={`setting-box ${finalRatingData.eligibleForSalaryIncrement === true ? 'active' : ''}`}
											icon={<AcceptIcon />}
											text={t('yesLabel')}
											disabled={
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
												!finalRatingData.isSubmitBtnVissible
											}
											handleClick={(event) => {
												setFinalRatingData({ ...finalRatingData, eligibleForSalaryIncrement: true });
											}}
										/>
										<OKRButton
											className={`setting-box ${finalRatingData.eligibleForSalaryIncrement === false ? 'active' : ''}`}
											icon={<NoUnitsIcon />}
											text={t('noLabel')}
											disabled={
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
												!finalRatingData.isSubmitBtnVissible
											}
											handleClick={(event) => {
												setFinalRatingData({
													...finalRatingData,
													eligibleForSalaryIncrement: false,
													salaryIncrement: '',
												});
												setFinalRatingDataError({ ...finalRatingDataError, salaryIncrement: '' });
											}}
										/>
									</Box>
									{finalRatingData.eligibleForSalaryIncrement === true && (
										<Box
											className={`setting-box-field ${
												finalRatingDataError && finalRatingDataError.salaryIncrement ? 'helperText-bottom' : ''
											}`}
										>
											<Box className='final-rating-label'>
												<RecommendedIcon />
												<Typography>
													{t('incrementRecommended')} (%)<i style={{ color: 'red' }}>*</i>
												</Typography>
											</Box>
											<TextField
												value={finalRatingData.salaryIncrement}
												name='Increment Recommend'
												placeholder={t('enterValue')}
												disabled={
													finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
													!finalRatingData.isSubmitBtnVissible
												}
												onChange={(e) => {
													setFinalRatingData({ ...finalRatingData, salaryIncrement: e.target.value });
													setFinalRatingDataError({ ...finalRatingDataError, salaryIncrement: '' });
												}}
												//error={errorMessage}
												// helperText={
												// 	errorMessage && (
												// 		<React.Fragment>
												// 			<ErrorIcon />
												// 			{errorMessage}
												// 		</React.Fragment>
												// 	)
												// }
												// onChange={(e) => {
												// 	setInputValue(e.target.value);
												// 	setErrorMessage('');
												// }}
											/>
											{finalRatingDataError && finalRatingDataError.salaryIncrement && (
												<Typography className='error-field'>
													<ErrorIcon />
													{finalRatingDataError.salaryIncrement}
												</Typography>
											)}
										</Box>
									)}
								</Box>
								<Box className='final-rating-eligible-box'>
									<Box className='final-rating-label'>
										<DesignationIcon />
										<Typography>
											{t('eligibleForPromotion')}
											<i style={{ color: 'red' }}>*</i>
										</Typography>
									</Box>
									<Box className='setting-box-list'>
										<OKRButton
											className={`setting-box ${finalRatingData.eligibleForPromotion === true ? 'active' : ''}`}
											icon={<AcceptIcon />}
											text={t('yesLabel')}
											disabled={
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
												!finalRatingData.isSubmitBtnVissible
											}
											handleClick={(event) => {
												setFinalRatingData({ ...finalRatingData, eligibleForPromotion: true });
											}}
										/>
										<OKRButton
											className={`setting-box ${finalRatingData.eligibleForPromotion === false ? 'active' : ''}`}
											icon={<NoUnitsIcon />}
											text={t('noLabel')}
											disabled={
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
												!finalRatingData.isSubmitBtnVissible
											}
											handleClick={(event) => {
												setFinalRatingData({
													...finalRatingData,
													eligibleForPromotion: false,
													promotionDesignation: '',
												});
												setFinalRatingDataError({
													...finalRatingDataError,
													promotionDesignation: '',
												});
											}}
										/>
									</Box>
									{finalRatingData.eligibleForPromotion === true && (
										<Box
											className={`setting-box-field ${
												finalRatingDataError && finalRatingDataError.promotionDesignation ? 'helperText-bottom' : ''
											}`}
										>
											<Box className='final-rating-label'>
												<RecommendedIcon />
												<Typography>
													{t('recommendedPromotion')}
													<i style={{ color: 'red' }}>*</i>
												</Typography>
											</Box>
											<TextField
												value={finalRatingData.promotionDesignation}
												name='Recommended Promotion Position?'
												placeholder={t('enterDesignation')}
												onChange={(e) => {
													setFinalRatingData({ ...finalRatingData, promotionDesignation: e.target.value });
													setFinalRatingDataError({ ...finalRatingDataError, promotionDesignation: '' });
												}}
												disabled={
													finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 ||
													!finalRatingData.isSubmitBtnVissible
												}
											/>
											{finalRatingDataError && finalRatingDataError.promotionDesignation && (
												<Typography className='error-field'>
													<ErrorIcon />
													{finalRatingDataError.promotionDesignation}
												</Typography>
											)}
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					className={`final-rating-comment-box ${
						finalRatingDataError && finalRatingDataError.finalComment ? 'helperText-bottom' : ''
					}`}
				>
					<Box className='final-rating-label'>
						<CommentIcon />
						<Typography variant='h4' className='font-weight-normal'>
							{t('finalCommentsLabel')}
							<i style={{ color: 'red' }}>*</i>
						</Typography>
					</Box>
					<Box className={`final-rating-comment-field helperText-bottom editior-pane long-text preview-overlay`}>
						{finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 || !finalRatingData.isSubmitBtnVissible ? (
							<Box className='view-only-comment long-text'>
								<span dangerouslySetInnerHTML={{ __html: finalRatingData.finalComment || 'No comment' }}></span>
							</Box>
						) : (
							<>
								<CkEditor
									placeholder={t('finalCommentsPlaceholder')}
									showEmoticons={true}
									value={finalRatingData.finalComment}
									handleEditorChange={(value: string) => {
										if (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>') {
										} else {
										}
										setFinalRatingData({ ...finalRatingData, finalComment: value });
										//setIsFormEdited(true);
										setFinalRatingDataError({ ...finalRatingDataError, finalComment: '' });
									}}
									disabled={
										finalRatingData.performanceGoalReviewWorkFlowTypeId === 5 || !finalRatingData.isSubmitBtnVissible
									}
								/>

								{finalRatingDataError && finalRatingDataError.finalComment && (
									<Typography className='error-field'>
										<ErrorIcon />
										{finalRatingDataError.finalComment}
									</Typography>
								)}
							</>
						)}
					</Box>
				</Box>

				<Box className='performance-goals-action'>
					<Box></Box>
					<Box>
						{finalRatingData.isReconciderBtnVissible && (
							<OKRButton
								className={'btn-link'}
								id='pip_create_submit_form'
								text={t('reconsiderLabel')}
								handleClick={() => {
									setModalProps({
										open: true,
										type: 'reconsider',
										message:
											finalRatingData.performanceGoalReviewWorkFlowTypeId === 3
												? t('skipReconsider')
												: finalRatingData.performanceGoalReviewWorkFlowTypeId === 4
												? t('buReconsider')
												: finalRatingData.performanceGoalReviewWorkFlowTypeId === 5
												? t('hrReconsider')
												: t('skipReconsider'),
										details: 3,
									});
								}}
							/>
						)}
						{finalRatingData.isDraftBtnVissible && (
							<OKRButton
								className={'btn-link'}
								id='final_rating_saveAsDraft_form'
								text={t('saveAsDraft')}
								handleClick={() => {
									handleSave(true);
								}}
							/>
						)}
						{finalRatingData.isSubmitBtnVissible && (
							<OKRButton
								className={'btn-primary'}
								id='final_rating_submit_form'
								text={
									finalRatingData.performanceGoalReviewWorkFlowTypeId === 4
										? t('Approve')
										: finalRatingData.performanceGoalReviewWorkFlowTypeId === 5
										? t('Publish')
										: t('submit')
								}
								handleClick={() => {
									let isError = validateForm(false);
									if (!isError) {
										setModalProps({
											open: true,
											type: 'submit',
											message:
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 3 ||
												finalRatingData.performanceGoalReviewWorkFlowTypeId === 4
													? t('Are you sure you want to submit to next stage of approval?')
													: t('Are you sure you want to send your rating for skip review?'),
											details: null,
										});
									}
								}}
							/>
						)}
					</Box>
				</Box>
			</Box>
			{categorySettingsOpen.open && (
				<ReviewConversationsSettings
					{...props}
					t={t}
					categorySettingsOpen={categorySettingsOpen}
					handleSettingsDrawerClose={handleSettingsDrawerClose}
					selectTab={currentTab}
					teamReviewUserListResponse={
						drUserData?.teamReviewUserListResponse ? drUserData.teamReviewUserListResponse : drUserData
					}
				/>
			)}

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
