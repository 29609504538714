import React, { useEffect, useState } from 'react';
import { Box, ClickAwayListener, Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CommentIcon, DownloadIcon } from '../../../config/svg/CommonSvg';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { BottomArrowIcon, FinalRatingIcon2 } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useDispatch, useSelector } from 'react-redux';
import { saveEmployeesFinalRating, setTeamUserDataCompleted } from '../../../action/perform';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../config/enums';
import { AssignedTrainings } from './AssignedTrainings';
import { getUserDetails } from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { InfoprolearningDomainName } from '../../../config/constant';
import { FinalAssessmentGuideTemplateUrl } from '../../../config/url';

export const FinalRatingAssessmentForm: React.FC<any> = (props: any) => {
	const { setIsFormEdited, selectedUserAssessment, setSelectedUserAssessment, isFinalSubmitted, setIsFinalSubmitted } =
		props;
	const { t } = useTranslation();
	const [formDisabled, setFormDisabled] = React.useState<any>(false);
	const [openFinalRating, setOpenFinalRating] = React.useState<Boolean>(false);
	const [isFinalCommentMandatory, setIsFinalCommentMandatory] = React.useState<Boolean>(false);
	const [selectedRating, setSelectedRating] = useState<any>(null);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const [finalRatingList, setFinalRatingList] = React.useState<any>([]);
	const [finalRatingComment, setFinalRatingComment] = React.useState<any>('');
	const [errorData, setErrorData] = React.useState<any>({ rating: false, comment: false });
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { employeeFinalRatingData, takeAssessmentData, teamAssessmentResponse } = useSelector(
		(state: any) => state.performReducer
	);
	const userDetail = getUserDetails();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');

	useEffect(() => {
		if (employeeFinalRatingData && employeeFinalRatingData.length) {
			if (!employeeFinalRatingData[0].isShowFinalRatingField) {
				handleFinalRatingClick({ preventDefault: () => {} }, { performanceRatingId: 0, ratingName: '' }, false);
			}
			setFinalRatingList(employeeFinalRatingData);
			//setFinalRatingScore(employeeFinalRatingData[0].finalScore);
			//setFinalRating(employeeFinalRatingData[0].finalRating);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeFinalRatingData]);
	useEffect(() => {
		if (selectedUserAssessment && selectedUserAssessment.isPerformanceCycleDone) {
			setFinalRatingList(employeeFinalRatingData);
			let updateData = {
				finalRatingId: selectedUserAssessment.finalRatingId,
				ratingName: selectedUserAssessment.finalRating,
				open: false,
			};
			setFinalRatingList([updateData]);
			setSelectedRating(updateData);
			setFinalRatingComment(selectedUserAssessment.finalScoreComment);
			setFormDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserAssessment]);
	useEffect(() => {
		if (isFinalSubmitted) {
			setFormDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFinalSubmitted]);

	useEffect(() => {
		if (!teamAssessmentResponse?.myTeamsAssessment?.isFinalRatingAssessmentPeriodOpen) {
			setFormDisabled(true);
		}
		if (
			teamAssessmentResponse &&
			teamAssessmentResponse.myTeamsAssessment &&
			teamAssessmentResponse.myTeamsAssessment.isFinalCommentMandatory
		) {
			setIsFinalCommentMandatory(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamAssessmentResponse]);

	const handleClickAway = () => {
		setOpenFinalRating(false);
	};
	const handleRatingDropdown = () => {
		setOpenFinalRating((prev: Boolean) => !prev);
	};
	const handleRatingClickAway = () => {
		setOpenFinalRating(false);
	};

	const handleFinalRatingClick = (e: any, rating: any, isDataSelected: any) => {
		e.preventDefault();
		setErrorData({ ...errorData, rating: false });
		setSelectedRating(rating || null);
		setOpenFinalRating(false);
		let updateData = [...finalRatingList];
		if (updateData.length > 0) {
			updateData[0].finalRatingId = rating.performanceRatingId;
			updateData[0].ratingName = rating.ratingName;
			updateData[0].open = false;
		} else {
			updateData.push({ finalRatingId: rating.performanceRatingId, ratingName: rating.ratingName, open: false });
		}
		setFinalRatingList(updateData);
		if (isDataSelected) {
			//setIsFormEdited(true);
		}
	};

	const handleFinalRatingSubmitClick = async () => {
		try {
			let data = {
				finalRatingScoreRequest: finalRatingList,
			};
			if (showFinalRatingDropdown()) {
				data.finalRatingScoreRequest = finalRatingList.filter((item: any) => item.finalRatingId > 0);
			}
			let errorList: any = {};
			if (isFinalCommentMandatory && (finalRatingComment === '' || finalRatingComment === '<p><p>')) {
				errorList.comment = true;
			}

			if (data && data.finalRatingScoreRequest.length === 0 && showFinalRatingDropdown()) {
				errorList.rating = true;
			}
			setErrorData(errorList);
			if (Object.keys(errorList).length === 0) {
				setFormDisabled(true);
				data.finalRatingScoreRequest[0].finalScoreComment = finalRatingComment;
				data.finalRatingScoreRequest[0].commitedOkrScore = data.finalRatingScoreRequest[0].okrScore;
				data.finalRatingScoreRequest[0].finalScore = data.finalRatingScoreRequest[0].calculatedFinalScore;
				let response: any = await dispatch(saveEmployeesFinalRating(data));
				if (response.data.status === Enums.STATUS_SUCCESS) {
					enqueueSnackbar(response.data.messageList.assessment, {
						variant: 'success',
						autoHideDuration: 2000,
					});
					setIsFormEdited(false);
					setIsFinalSubmitted(true);
					setSelectedUserAssessment({
						...selectedUserAssessment,
						...response.data.entity.finalRatingScoreRequest[0],
						finalRating: data.finalRatingScoreRequest[0].ratingName,
						isPerformanceCycleDone: true,
					});
					dispatch(
						setTeamUserDataCompleted({
							...takeAssessmentData,
							...response.data.entity.finalRatingScoreRequest[0],
							finalRating: data.finalRatingScoreRequest[0].ratingName,
						})
					);
				} else {
					setFormDisabled(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const showFinalRatingDropdown = () => {
		return finalRatingList && finalRatingList.length > 0 && finalRatingList[0].isShowFinalRatingField;
	};
	return (
		<>
			<Box className='assessment-final-rating-area'>
				<Box className='role-transition-guide-link'>
					{tenantData && tenantData.domainName === InfoprolearningDomainName && (
						<>
							{/* <Typography variant='h5'>
								<Link
									id={'download-temp-final-assess-guide'}
									target='_blank'
									rel='noopener noreferrer'
									href={FinalAssessmentGuideTemplateUrl}
								>
									<span>
										<DownloadIcon />
									</span>
									{t('Potential Rating Guide')}
								</Link>
							</Typography> */}
						</>
					)}
				</Box>
				<Box className='final-rating-head'>
					<Typography variant='h4'>{t('finalAssessment')}</Typography>
				</Box>
				<Box className='final-rating-panel light-blue-panel'>
					{showFinalRatingDropdown() && (
						<Box className='select-rating-box'>
							<Box className='final-rating-label'>
								<FinalRatingIcon2 />
								<Typography variant='h4' className='font-weight-normal'>
									{t('ratingLabel')}
									<i style={{ color: 'red' }}>*</i>
								</Typography>
							</Box>
							<Box className='final-rating-select-box helperText-bottom'>
								<Box className={`ques-type-selection custom-type-dropdown-top ${openFinalRating ? 'active' : ''}`}>
									<OKRButton
										disabled={formDisabled}
										className={`dropdown-button button-placeholder ${errorData?.rating && 'error'}`}
										text={selectedRating?.ratingName || '- Select -'}
										icon={<BottomArrowIcon />}
										handleClick={() => handleRatingDropdown()}
									></OKRButton>
									{errorData?.rating ? (
										<Typography className='error-field'>Please select rating type</Typography>
									) : (
										<></>
									)}
									{openFinalRating && (
										<ClickAwayListener onClickAway={handleRatingClickAway}>
											<Box className='ques-type-dropdown'>
												<List component='div' disablePadding>
													{okrMasterData?.performanceRatings && okrMasterData.performanceRatings?.length ? (
														okrMasterData?.performanceRatings.map((rating: any) => (
															<ListItem
																component='div'
																className={
																	rating?.performanceRatingId === selectedRating?.performanceRatingId ? 'active' : ''
																}
																onClick={(e) => handleFinalRatingClick(e, rating, true)}
															>
																<Typography>{rating?.ratingName || ''}</Typography>
															</ListItem>
														))
													) : (
														<></>
													)}
												</List>
											</Box>
										</ClickAwayListener>
									)}
								</Box>
							</Box>
						</Box>
					)}
					<Box className='final-rating-comment-box'>
						<Box className='final-rating-label'>
							<CommentIcon />
							{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
								<>
									<Tooltip
										arrow
										title={`Use the "Potential Rating Guide" to accurately assess an team member's potential by rating them as High, Medium, or Emerging based on clear and concise criteria.`}
										aria-label=''
										classes={{ popper: 'tooltip-layout-auto' }}
									>
										<Typography variant='h4' className='font-weight-normal'>
											{t('reflectionOrRemarksLabelInfoPro')}
											{isFinalCommentMandatory && <i style={{ color: 'red' }}>*</i>}
										</Typography>
									</Tooltip>
								</>
							) : (
								<>
									<Typography variant='h4' className='font-weight-normal'>
										{t('reflectionOrRemarksLabel')}
										{isFinalCommentMandatory && <i style={{ color: 'red' }}>*</i>}
									</Typography>
								</>
							)}
						</Box>
						<Box
							className={`final-rating-comment-field helperText-bottom editior-pane long-text preview-overlay ${
								errorData?.comment ? 'error' : ''
							}`}
						>
							{formDisabled ? (
								<Box className='view-only-comment long-text'>
									<span dangerouslySetInnerHTML={{ __html: finalRatingComment || 'No comment' }}></span>
								</Box>
							) : (
								<>
									<CkEditor
										placeholder={t('writeYourComments')}
										showEmoticons={true}
										value={''}
										handleEditorChange={(value: string) => {
											if (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>') {
												setErrorData({ ...errorData, comment: true });
											} else {
												setFinalRatingComment(value);
												setErrorData({ ...errorData, comment: false });
											}
											setFinalRatingComment(value);
											//setIsFormEdited(true);
										}}
									/>
									{errorData?.comment ? <Typography className='error-field'>Please add comment</Typography> : <></>}
								</>
							)}
						</Box>
					</Box>
					{!formDisabled && (
						<Box className='final-rating-action'>
							<OKRButton
								/*disabled={
								(showFinalRatingDropdown() && errorData.rating === true) ||
								(isFinalCommentMandatory && errorData.comment === true)
							}*/
								className={'btn-primary'}
								id={'btn-submit'}
								text={t('Submit')}
								handleClick={handleFinalRatingSubmitClick}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box className='final-rating-comment-mssg'>
				<Typography variant='h6' className='font-weight-normal'>
					{tenantData && tenantData.domainName === InfoprolearningDomainName
						? t('reflectionOrRemarksEditMessageInfoPro')
						: t('reflectionOrRemarksEditMessage')}
				</Typography>
			</Box>
			{/* J & J Demo {userDetail.employeeId === 819 && <AssignedTrainings />} */}
		</>
	);
};
