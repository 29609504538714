import React, { Fragment, useState, useEffect } from 'react';
import { Box, Chip, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SortingIcon } from '../../../../../config/svg/CommonSvgIcon';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import NoDataImg from '../../../../../images/my-goal-bg.svg';
import '../../../../../styles/pages/admin/settings/align-goals/goalWorkflowList.scss';
import { useDispatch } from 'react-redux';
import { setGoalCycleWorkflowPaginationData, reviewWorkflowReversalList } from '../../../../../action/adminSettings';
import { getFullName, getMonthDateYear } from '../../../../../config/utils';
import { Enums } from '../../../../../config/enums';

export const ReviewWorkflowReversalList: React.FC<any> = (props: any) => {
	const {
		setLoader,
		goalCycleSearchTextArray,
		pageIndex,
		pageSize,
		selectedReviewWorkflowFilterCycleId,
		finalFilterCondition,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [workflowReversalListData, setWorkflowReversalListData] = useState<any>(null);
	const [sortingData, setSortingData] = useState<any>({ column: '', order: '' });

	useEffect(() => {
		//getWorkflowReversalList();
	}, []);

	useEffect(() => {
		getWorkflowReversalList({
			finder: goalCycleSearchTextArray,
			pageIndex: pageIndex,
			sortingText: sortingData.column,
			performanceGoalReviewCycleId: selectedReviewWorkflowFilterCycleId,
			order: sortingData.order,
			pageSize: pageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleSearchTextArray, pageIndex, pageSize, selectedReviewWorkflowFilterCycleId, finalFilterCondition]);

	const getWorkflowReversalList = async (requestPayload: any) => {
		let requestParam = {
			...requestPayload,
			//performanceGoalCycleId: 0,
			startDate: null,
			endDate: null,
		};
		let filters: any = [];
		finalFilterCondition.map((value: any, index: number) => {
			filters.push(value.performanceCycleId);
		});
		requestParam.performanceGoalReviewCycleId = filters;
		setLoader(true);
		const response: any = await dispatch(reviewWorkflowReversalList(requestParam));
		if (response) {
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				dispatch(setGoalCycleWorkflowPaginationData(entity));
				if (entity) {
					const { records } = entity || {};
					if (records && records.length) {
						setWorkflowReversalListData(records);
					} else {
						setWorkflowReversalListData([]);
					}
				} else {
					setWorkflowReversalListData([]);
				}
				setLoader(false);
			} else {
				setLoader(false);
			}
		}
		setLoader(false);
	};

	const sortWorkflowReversalList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingData.column) {
			newSortOrder = sortingData.order === 'asc' ? 'desc' : 'asc';
		}
		setSortingData({ column: column, order: newSortOrder });

		getWorkflowReversalList({
			finder: goalCycleSearchTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: pageSize,
		});
	};
	return (
		<Box className='workflow-reversal-listing'>
			{workflowReversalListData && workflowReversalListData?.length > 0 ? (
				<Box className='okr-list-panel-head'>
					<List component='ul' disablePadding>
						<ListItem className='workflow-reversal-col1'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'type')}
								className={sortingData.column === 'type' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>
										{t('typeLabel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='workflow-reversal-col2'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'employeeDetails.firstName')}
								className={
									sortingData.column === 'employeeDetails.firstName'
										? `sort-title ${sortingData.order}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('nameLabel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='workflow-reversal-col3'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'performanceGoalReviewCycleName')}
								className={
									sortingData.column === 'performanceGoalReviewCycleName'
										? `sort-title ${sortingData.order}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('cycle')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='workflow-reversal-col4'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'oldStatusName')}
								className={
									sortingData.column === 'oldStatusName' ? `sort-title ${sortingData.order}` : 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('oldStatus')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='workflow-reversal-col5'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'newStatusName')}
								className={
									sortingData.column === 'newStatusName' ? `sort-title ${sortingData.order}` : 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('newStatus')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='workflow-reversal-col6'>
							<Typography
								variant='body2'
								onClick={(e) => sortWorkflowReversalList(e, 'reversalDateOn')}
								className={
									sortingData.column === 'reversalDateOn' ? `sort-title ${sortingData.order}` : 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('reversalDoneOn')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
					</List>
				</Box>
			) : (
				<></>
			)}
			{workflowReversalListData && workflowReversalListData?.length > 0 ? (
				workflowReversalListData.map((item: any, index: number) => {
					const userFullName = getFullName(item?.employeeDetails);
					return (
						<Box className={`workflow-reversal-list-content`}>
							<List component='ul' disablePadding key={`review-form-list-${index}`}>
								<ListItem className='workflow-reversal-col1'>
									<Typography>{item.type === true ? 'All Employees' : 'Sinlgle Employee'}</Typography>
								</ListItem>
								<ListItem className='workflow-reversal-col2'>
									{item.type === true ? (
										<Typography>
											<strong>--</strong>
										</Typography>
									) : (
										<>
											{userFullName && userFullName.length > 35 ? (
												<Tooltip arrow title={userFullName || ''}>
													<Typography>{userFullName.substring(0, 35)}...</Typography>
												</Tooltip>
											) : (
												<Typography>{userFullName || ''}</Typography>
											)}
										</>
									)}

									<Typography className='subtitle3'>{item?.employeeDetails.emailId}</Typography>
								</ListItem>
								<ListItem className='workflow-reversal-col3'>
									<Typography>{item.performanceGoalReviewCycleName}</Typography>
								</ListItem>
								<ListItem className='workflow-reversal-col4'>
									<Box className='workflow-reversal-status'>
										<Chip
											className={
												item?.oldStatusId === Enums.ZERO
													? 'default-grey-chip'
													: item?.oldStatusId === Enums.ONE
													? 'draft-chip'
													: item?.oldStatusId === Enums.TWO
													? 'lagging-chip'
													: item?.oldStatusId === Enums.THREE
													? 'default-grey-chip'
													: item?.oldStatusId === Enums.FOUR
													? 'on-track-chip'
													: ''
											}
											label={item.oldStatusName}
										/>
									</Box>
								</ListItem>
								<ListItem className='workflow-reversal-col5'>
									<Box className='workflow-reversal-status'>
										<Chip
											className={
												item?.newStatusId === Enums.ZERO
													? 'default-grey-chip'
													: item?.newStatusId === Enums.ONE
													? 'draft-chip'
													: item?.newStatusId === Enums.TWO
													? 'lagging-chip'
													: item?.newStatusId === Enums.THREE
													? 'default-grey-chip'
													: item?.newStatusId === Enums.FOUR
													? 'on-track-chip'
													: ''
											}
											label={item.newStatusName}
										/>
									</Box>
								</ListItem>
								<ListItem className='workflow-reversal-col6'>
									<Typography>{item?.reversalDateOn ? getMonthDateYear(item?.reversalDateOn) : ''}</Typography>
								</ListItem>
							</List>
						</Box>
					);
				})
			) : (
				<NoRecord
					noRecordMessage={t('noReviewWorkflowReversal')}
					subHead={t('clickPlusIconToReviewWorkflowReverse')}
					noDataImgWidth={'516'}
					noImg={NoDataImg}
					{...props}
				/>
			)}
		</Box>
	);
};
